import apiUrls from "store/api/urls";
import axios from "axios";
import { MembershipQuery } from "types/membershipQuery";
import { Speaker } from "types/speaker";
import { MembershipServiceResponse } from "types/membership_service_response";
import statusMapper from "types/status_mapper";

const fetchMembersList = async (
  param: MembershipQuery
): Promise<{ data: Array<Speaker>; statusCode: number }> => {
  const apiResult = await axios.get(apiUrls.getMembershipList({ ...param }), {
    baseURL: "https://api.livetips.crowddp.com/",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      Authorization: `Bearer ${param.token}`,
    },
  });

  const res: Array<Speaker> = apiResult.data.results.map(
    (entry: MembershipServiceResponse) => ({
      name: entry.user.name,
      email: entry.user.email,
      org: entry.user.org,
      mobile: entry.user.mobile,
      status: statusMapper[entry.status],
      id: entry.user.customerId,
    })
  );
  return { data: res, statusCode: apiResult.status };
};

export default fetchMembersList;
