import { object, string } from "yup";

export enum FormNames {
  mobile = "mobile",
  password = "passWord",
}

const initialValues = {
  [FormNames.mobile]: "",
  [FormNames.password]: "",
};

const validationSchema = object({
  [FormNames.mobile]: string().required(),
  [FormNames.password]: string().required(),
});

export { initialValues, validationSchema };
