import rootApi from "store/api";
import {
  generateTokenAction,
  getRolesAction,
  preResetPwdAction,
  refreshTokenAction,
  resetPwdAction,
  verifyTokenAction,
} from "./action_creators";
import {
  GenerateToken,
  GenerateTokenResponse,
  PreResetPwd,
  ResetPwd,
  VerifyToken,
} from "../types";

export const identityServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    generateToken: builder.mutation<GenerateTokenResponse, GenerateToken>({
      query: (body) => generateTokenAction(body),
    }),
    verifyToken: builder.mutation<void, VerifyToken>({
      query: (body) => verifyTokenAction(body),
    }),
    refreshToken: builder.mutation<void, VerifyToken>({
      query: (body) => refreshTokenAction(body),
    }),
    resetPwd: builder.mutation<void, ResetPwd>({
      query: (body) => resetPwdAction(body),
    }),
    preResetPwd: builder.mutation<void, PreResetPwd>({
      query: (body) => preResetPwdAction(body),
    }),
    getRoles: builder.query<void, void>({
      query: () => getRolesAction(),
    }),
  }),
});

export const {
  useGenerateTokenMutation,
  useGetRolesQuery,
  usePreResetPwdMutation,
  useRefreshTokenMutation,
  useResetPwdMutation,
  useVerifyTokenMutation,
} = identityServiceApi;
