import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  button: {
    ":hover": {
      backgroundColor: "white",
    },
    width: {
      xs: "100%",
    },
    whiteSpace: "nowrap",
    paddingY: 1,
    paddingX: 10,
    borderRadius: 3,
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
