import { createTheme } from "@mui/material/styles";
import color_pallette from "./color_pallette";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: color_pallette.primary,
      dark: color_pallette.primary,
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: color_pallette.primary,
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

export default theme;
