import { FC, PropsWithChildren, useMemo } from "react";
import { Box, BoxProps, SxProps } from "@mui/material";
import styles from "./styles";
import Menu from "common/menu";
import { MenuListItem } from "types/menu_list_item";

type Props = {
  menuOptions: Array<MenuListItem>;
  extraStyles?: SxProps<BoxProps>;
};

const OptionsHeader: FC<PropsWithChildren<Props>> = ({
  children,
  menuOptions,
  extraStyles,
}) => {
  const style = useMemo(() => {
    return { ...styles.container, ...extraStyles };
  }, [extraStyles]);

  return (
    <Box data-testid="option_header" sx={style}>
      <>{children}</>
      <Menu menuItems={menuOptions} />
    </Box>
  );
};

export default OptionsHeader;
