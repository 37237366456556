import BlogService from "services/blog/Blog";
import Service from "services/Service";
import InsightService from "services/insight/Insight";
import InsightsIcon from "@mui/icons-material/Insights";
import { NavigationRoutes } from "navigation/routes";
import DashboardService from "./dashboard/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookIcon from "@mui/icons-material/Bookmark";
import CastIcon from "@mui/icons-material/Cast";
import Dashboard from "pages/dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import Blog from "pages/blog";
import Online from "pages/online";
import Insights from "pages/insights";
import messages from "./messages";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SpeakerService from "./speaker/Speaker";
import Speakers from "pages/speakers";
import SpeakerDetails from "pages/speaker_details";
import OnlineDetails from "pages/online_details";
import OnlineService from "./online/Online";
import PodcastService from "./podcast/Podcast";
import Podcast from "pages/podcast";
import PodcastDetails from "pages/podcast_details";
import AdminService from "./admins/Admins";
import Admins from "pages/admins";
import AdminListing from "pages/admin_listing";

const dashboard = new DashboardService(
  messages.dashboard.id,
  NavigationRoutes.dashboard,
  null,
  [16, 9],
  <DashboardIcon />,
  <Dashboard />,
  null
);
Service.addService(dashboard);

const blog = new BlogService(
  messages.blog.id,
  NavigationRoutes.blog,
  null,
  [16, 9],
  <BookIcon />,
  <Blog />,
  null
);
Service.addService(blog);

const admin = new AdminService(
  "Admins",
  NavigationRoutes.admins,
  null,
  [16, 9],
  <AdminPanelSettingsIcon />,
  <Admins />,
  null
);
Service.addService(admin);

const online = new OnlineService(
  "Online Videos",
  NavigationRoutes.online,
  null,
  [11, 8],
  <CastIcon />,
  <Online />,
  <OnlineDetails />
);
Service.addService(online, "nested");

const podcast = new PodcastService(
  "Podcasts",
  NavigationRoutes.podcast,
  null,
  [11, 8],
  <CastIcon />,
  <Podcast />,
  <PodcastDetails />
);
Service.addService(podcast, "nested");

const speaker = new SpeakerService(
  "Speakers",
  NavigationRoutes.speakers,
  null,
  [16, 11],
  <GroupsIcon />,
  <Speakers />,
  <SpeakerDetails />
);
Service.addService(speaker, "nested");

const adminListing = new SpeakerService(
  "Admin List",
  NavigationRoutes.admin_list,
  null,
  [16, 11],
  <GroupsIcon />,
  <AdminListing />,
  null
);
Service.addService(adminListing, "nested");

const insight = new InsightService(
  messages.insight.id,
  NavigationRoutes.insights,
  [online, speaker, podcast, adminListing],
  [16, 11],
  <InsightsIcon />,
  <Insights />,
  null
);
Service.addService(insight);
