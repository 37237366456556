import { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import SubmitButton from "common/submit_button";
import {
  useRegisterUserMutation,
  useValidateUserMutation,
} from "store/api/auth_service/endpoints";
import { Request } from "store/api/types";
import { validationSchema, initialValues } from "./form_data";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { ValidateResponse } from "types/validate_response";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import AddAdminForm from "../add_admin_form";
import FormContainer from "common/form_container";
import useSnack from "hooks/useSnack";

const RegisterForm: FC = () => {
  const [register] = useRegisterUserMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [validate] = useValidateUserMutation();

  const { triggerSnack } = useSnack();

  const isAdminEmail = (email: string): boolean => {
    return email.includes("@gov.ae");
  };

  const handleSubmit = async (values: Request) => {
    if (isAdminEmail(values.email)) {
      const { error } = (await register(values)) as {
        error: { data: string; originalStatus: number };
      };
      if (error.originalStatus === 200) {
        dispatch(globalSliceActions.submitUserTransactionId(error.data));

        try {
          (await validate({
            email: values.email,
            mobile: values.mobile,
            otp: "1111",
            transactionId: error.data,
          })) as unknown as { data: ValidateResponse };

          navigate(NavigationRoutes.admin_list);
        } catch (error: unknown) {
          console.log("ERROR VALIDATING USER #### ", error);
        }
      }
    } else {
      triggerSnack("Email is Not Recognized as Admin", "error");
    }
  };

  return (
    <Box sx={styles.container}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Grid justifyContent="center" container rowGap={2} columnGap={1.5}>
          <AddAdminForm />
        </Grid>
        <Stack
          alignItems="center"
          direction="column"
          sx={styles.buttonContainer}
        >
          <SubmitButton extraStyles={styles.button} title="Submit Admin" />
        </Stack>
      </FormContainer>
    </Box>
  );
};

export default RegisterForm;
