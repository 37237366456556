import { defineMessages } from "react-intl";

const scope = "Drawer";

export default defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  insights: {
    id: `${scope}.insights`,
    defaultMessage: "Insights",
  },
  question_answer: {
    id: `${scope}.question_answer`,
    defaultMessage: "Q&A",
  },
  setting: {
    id: `${scope}.setting`,
    defaultMessage: "Settings",
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: "Logout",
  },
});
