import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  modal: {
    width: 500,
    height: 400,
    borderRadius: 5,
    boxShadow: 1,
    overflow: "hidden",
    marginX: "auto",
    marginY: "auto",
  },
  cancelButton: {
    color: "black",
    width: 200,
    paddingBlock: 1,
    backgroundColor: color_pallette.backgroundGray,
  },
  container: {
    width: "100%",
    height: "100%",
    paddingBlock: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
};

export default styles;
