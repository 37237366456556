import apiUrls from "store/api/urls";
import generateFormData from "./generate_form_data";
import axios from "axios";

type Param = {
  id: string | number;
  token: string;
};

const updateOnlineVideo = async (body: object, param: Param) => {
  const formData = generateFormData(body);

  const { data } = await axios.patch(
    apiUrls.videoById(param.id.toString()),
    formData,
    {
      baseURL: "https://api.livetips.crowddp.com/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: `Bearer ${param.token}`,
      },
    }
  );

  console.log("ONLINE VIDEO UPDATED   ", data);
};

export default updateOnlineVideo;
