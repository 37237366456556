import rootApi from "store/api";
import {
  validateUserCredentials,
  submitRegisterCredentials,
  findAllUsersAction,
  getUserById,
  refreshTokenAction,
} from "./action_creators";
import {
  Authorization,
  FlattenUser,
  Request,
  UserQuery,
  UserResponse,
  Validation,
} from "../types";

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation<void, Request>({
      query: (body) => submitRegisterCredentials(body),
    }),
    getUserById: builder.query<UserResponse, UserQuery>({
      query: (body) => getUserById(body),
    }),
    validateUser: builder.mutation<void, Validation>({
      query: (body) => validateUserCredentials(body),
    }),
    refreshToken: builder.mutation<void, Authorization>({
      query: (body) => refreshTokenAction(body),
    }),
    findAllUsers: builder.query<Array<FlattenUser>, void>({
      query: () => findAllUsersAction(),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useFindAllUsersQuery,
  useValidateUserMutation,
  useGetUserByIdQuery,
  useRefreshTokenMutation,
} = authApi;
