import { Box } from "@mui/material";
import Collapsable from "components/collapsable";
import MenuItemButton from "components/menu_item_button";
import React, { FC, useState } from "react";
import Service from "services/Service";
import { ServiceGroup } from "services/types/service_group";
import styles from "./styles";
import SortIcon from "components/sort_icon";

type Props = {
  entry: Service;
};

const SidebarMenuItem: FC<Props> = ({ entry }) => {
  const [open, setOpen] = useState<boolean>(false);

  const Icon = (group: ServiceGroup): React.JSX.Element | null => {
    if (group === null) return null;
    return <SortIcon orientation={open ? "up" : "down"} />;
  };

  return (
    <>
      <Box sx={styles.menuButton}>
        <MenuItemButton
          toggleCollapsable={() => setOpen(!open)}
          entry={entry}
        />
        {Icon(entry.getServiceGroup())}
      </Box>
      {entry.getServiceGroup() !== null ? (
        <Collapsable open={open} serviceGroup={entry.getServiceGroup()} />
      ) : null}
    </>
  );
};

export default SidebarMenuItem;
