import React from "react";
import { UploadPrivacy } from "types/privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";

interface IFilterContext {
  category?: number;
  status?: UploadedFileStatus;
  privacy?: UploadPrivacy;
  setCategroy: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPrivacy: React.Dispatch<React.SetStateAction<number | undefined>>;
  setStatus: React.Dispatch<
    React.SetStateAction<UploadedFileStatus | undefined>
  >;
}

const FilterContext = React.createContext<IFilterContext>({
  setCategroy: () => {},
  setStatus: () => {},
  setPrivacy: () => {},
});

export default FilterContext;
