import { Speaker } from "types/speaker";
import statusMapper from "types/status_mapper";

export const rows: Array<Speaker> = [
  {
    id: 7,
    email: "seifkharrachi@gmail.com",
    mobile: "3535151011",
    name: "seif eddine kharrachi",
    org: "Crowd",
    status: statusMapper[0],
  },
  {
    email: "seifkharrachi@gmail.com",
    mobile: "3535151011",
    name: "seif eddine kharrachi",
    org: "Crowd",
    status: statusMapper[0],
    id: 35,
  },
  {
    email: "seifkharrachi@gmail.com",
    mobile: "3535151011",
    name: "seif eddine kharrachi",
    org: "Crowd",
    status: statusMapper[0],
    id: 33,
  },
  {
    email: "seifkharrachi@gmail.com",
    mobile: "3535151011",
    name: "seif eddine kharrachi",
    org: "Crowd",
    status: statusMapper[0],
    id: 11,
  },
  {
    email: "seifkharrachi@gmail.com",
    mobile: "3535151011",
    name: "seif eddine kharrachi",
    org: "Crowd",
    status: statusMapper[0],
    id: 111,
  },
];

export const headers = Object.keys(rows[0]) as Array<keyof Speaker>;
