import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import styles from "./styles";
import AreaChartSection from "../area_chart_section";
import PieChartSection from "../pie_section";
import OptionsHeader from "common/options_header";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const BodySection: FC = () => {
  return (
    <Grid container sx={styles.container}>
      <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
        <OptionsHeader
          extraStyles={{ marginBottom: 5 }}
          menuOptions={[
            { label: messages.plans.id, handleClick: () => {} },
            { label: messages.community.id, handleClick: () => {} },
            { label: messages.view_stats.id, handleClick: () => {} },
          ]}
        >
          <Typography sx={{ fontWeight: "bold", opacity: 0.7 }}>
            <FormattedMessage id={messages.reports.id} />
          </Typography>
        </OptionsHeader>
        <AreaChartSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <OptionsHeader
          extraStyles={{ marginBottom: 5 }}
          menuOptions={[
            { label: messages.plans.id, handleClick: () => {} },
            { label: messages.community.id, handleClick: () => {} },
            { label: messages.view_stats.id, handleClick: () => {} },
          ]}
        >
          <Typography sx={{ fontWeight: "bold", opacity: 0.7 }}>
            <FormattedMessage id={messages.analytics.id} />
          </Typography>
        </OptionsHeader>
        <PieChartSection />
      </Grid>
    </Grid>
  );
};

export default BodySection;