import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Event } from "types/table_event";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { speakerClassData } from "components/filters_modal/form";
import { useRefreshTokenMutation } from "store/api/auth_service/endpoints";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import fetchPodcastList from "utils/get_podcasts";
import { podcastActions } from "store/podcast_slice";
import updatePodcast from "utils/update_podcast";
import { UploadPrivacy } from "types/privacy";

const usePodcasts = () => {
  const [category, setCategroy] = useState<number>();
  const [status, setStatus] = useState<UploadedFileStatus>();
  const [privacy, setPrivacy] = useState<UploadPrivacy>();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const data = useAppSelector((state) => state.podcast);

  const dispatch = useAppDispatch();

  const [refresh] = useRefreshTokenMutation();

  const navigate = useNavigate();

  const fetchPodcastListing = async () => {
    const { data, statusCode } = await fetchPodcastList({
      token: access_token,
      category:
        category && category !== speakerClassData[0].value
          ? category.toString()
          : undefined,
      privacy:
        privacy && privacy !== UploadPrivacy.PUBLIC ? privacy : undefined,
      status: status && status !== UploadedFileStatus.ALL ? status : undefined,
    });
    if (statusCode === 401) {
      refresh({ token: access_token });
      await fetchPodcastListing();
    }
    dispatch(podcastActions.submitPodcasts(data));
  };

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);

    fetchPodcastListing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, access_token, status]);

  const handleItemReject: Event = async (id: string | number) => {
    await updatePodcast(
      { status: UploadedFileStatus.REJECTED },
      {
        id,
        token: access_token,
      }
    );
    fetchPodcastListing();
  };

  const handleItemAccept: Event = async (id: string | number) => {
    await updatePodcast(
      { status: UploadedFileStatus.ACCEPTED },
      {
        id,
        token: access_token,
      }
    );
    fetchPodcastListing();
  };

  return {
    handleItemAccept,
    handleItemReject,
    privacy,
    setPrivacy,
    data,
    category,
    setCategroy,
    status,
    setStatus,
  };
};

export default usePodcasts;
