import { IconButton, Toolbar, Tooltip, Typography, alpha } from "@mui/material";
import { FC } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormattedMessage } from "react-intl";
import FiltersModal from "components/filters_modal";

type EnhancedTableToolbarProps = {
  numSelected: number;
  messageId: string;
};

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = (props) => {
  const { numSelected, messageId } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormattedMessage id={messageId} />
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <FiltersModal />
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
