import { FC } from "react";
import { Grid } from "@mui/material";
import styles from "./styles";
import InsightsCard from "common/insights_card";
import { useAppSelector } from "store/hooks";

const HeaderSection: FC = () => {
  const { podcast, video, speaker } = useAppSelector((state) => state);
  return (
    <Grid
      container
      justifyContent={{
        lg: "start",
      }}
      alignContent={"center"}
      sx={styles.container}
    >
      <InsightsCard value={podcast.length.toString()} label="Total Podcasts" />
      <InsightsCard value={speaker.length.toString()} label="Total Speakers" />
      <InsightsCard value="4.3" label="Over All Rating" />
      <InsightsCard
        value={video.length.toString()}
        label="Total Online Videos"
      />
    </Grid>
  );
};

export default HeaderSection;
