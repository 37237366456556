import { defineMessages } from "react-intl";

const scope = "MainContent";

export default defineMessages({
  profileInsights: {
    id: `${scope}.profileInsights`,
    defaultMessage: "Profile Insights",
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: "Start Date",
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: "End Date",
  },
});
