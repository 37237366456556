import { FC } from "react";
import InputField from "common/input_field";
import data from "./data";
import { REJECTMODALFORMPROPS } from "./form_props";
import SelectionInputField from "common/select_input_field";

const RejectModalForm: FC = () => {
  return (
    <>
      <SelectionInputField
        placeholder="Spam"
        dataList={data}
        label="Spam"
        name={REJECTMODALFORMPROPS.reason}
      />
      <InputField
        name={REJECTMODALFORMPROPS.description}
        extraStyles={{ width: "80%" }}
        placeholder="Description"
        multiline
        maxRows={5}
      />
    </>
  );
};

export default RejectModalForm;
