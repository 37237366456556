import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import InputField from "common/input_field";
import SubmitButton from "common/submit_button";
import FormActionSection from "../form_action_section";
import { FormNames, initialValues, validationSchema } from "./form_data";
import messages from "./messages";
import { useIntl } from "react-intl";
import FormContainer from "common/form_container";
import { GenerateToken, GenerateTokenResponse } from "store/api/types";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useGenerateTokenMutation } from "store/api/identity_service/endpoints";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { JwtPayload, jwtDecode } from "jwt-decode";
import useSnack from "hooks/useSnack";

const LoginForm: FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  const [generate, { isError }] = useGenerateTokenMutation();

  const dispatch = useAppDispatch();

  const isAdminEmail = (email: string): boolean => {
    return email.includes("@gov.ae");
  };

  const { triggerSnack } = useSnack();

  const handleSubmit = async (values: GenerateToken) => {
    if (isAdminEmail(values.mobile)) {
      const res = (await generate(values)) as { data: GenerateTokenResponse };
      if (!isError) {
        const decodedUser = jwtDecode<JwtPayload>(res.data.access_token);
        dispatch(
          globalSliceActions.submitUserCredentials({
            ...res.data,
            userData: { ...decodedUser } as GenerateTokenResponse["userData"],
          })
        );
        navigate(NavigationRoutes.dashboard);
      }
    } else {
      triggerSnack("Access Restricted", "error");
    }
  };

  return (
    <Box sx={styles.container}>
      <FormContainer
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Box sx={styles.formHeader}>
          <InputField
            label={intl.formatMessage(messages.mobile)}
            name={FormNames.mobile}
            placeholder={intl.formatMessage(messages.mobilePlaceholder)}
            extraStyles={styles.spacing}
          />
          <InputField
            label={intl.formatMessage(messages.password)}
            name={FormNames.password}
            type="password"
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            extraStyles={styles.spacing}
          />
          <FormActionSection />
        </Box>
        <Box sx={styles.center}>
          <SubmitButton title={intl.formatMessage(messages.submitLabel)} />
        </Box>
      </FormContainer>
    </Box>
  );
};

export default LoginForm;
