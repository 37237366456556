import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import color_pallette from "theme/color_pallette";

const data = [
  {
    name: "10am",
    views: 400,
  },
  {
    name: "11am",
    views: 250,
  },
  {
    name: "12am",
    views: 200,
  },
  {
    name: "1am",
    views: 278,
  },
  {
    name: "2am",
    views: 189,
  },
  {
    name: "3am",
    views: 239,
  },
  {
    name: "4am",
    views: 349,
  },
];

const AreaChartSection: FC = () => {
  return (
    <Box sx={styles.container}>
      <AreaChart
        width={850}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray=".5 .5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          itemStyle={{
            color: "white",
          }}
          contentStyle={{
            backgroundColor: color_pallette.iconTint,
            borderRadius: 10,
            borderColor: color_pallette.lightPrimary,
            color: "white",
            textAlign: "center",
          }}
        />
        <Area
          type="bump"
          dataKey="views"
          stroke={color_pallette.primary}
          fill={color_pallette.iconTint}
        />
      </AreaChart>
    </Box>
  );
};

export default AreaChartSection;
