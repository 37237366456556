import { FC } from "react";
import { Box, Typography } from "@mui/material";
import Card from "components/card";
import UaePassButton from "common/uae_pass_button";
import styles from "./styles";
import RegisterForm from "./register_form";
import SnackBar from "components/snack_bar";

const RegisterPage: FC = () => {
  return (
    <>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.font}>Sign up</Typography>
      </Box>
      <Box sx={styles.container}>
        <Card extraStyle={styles.cardStyle}>
          <RegisterForm />
          <Box sx={styles.orSection}>
            <SnackBar />
            <Typography sx={styles.orLabel}>Or</Typography>
          </Box>
          <Box sx={styles.center}>
            <UaePassButton />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default RegisterPage;
