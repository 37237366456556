import { object, string } from "yup";

export enum RegisterFormNames {
  username = "name",
  nationality = "nationality",
  country = "country",
  email = "email",
  university = "org",
  password = "passWord",
  mobile = "mobile",
  role = "role",
  /* gender = "gender", */
}

const initialValues = {
  [RegisterFormNames.username]: "",
  [RegisterFormNames.password]: "",
  [RegisterFormNames.nationality]: "United Arab Emirates",
  [RegisterFormNames.email]: "",
  [RegisterFormNames.mobile]: "",
  [RegisterFormNames.university]: "",
  [RegisterFormNames.country]: "United Arab Emirates",
  [RegisterFormNames.role]: "admin",
  /* [RegisterFormNames.gender]: "male", */
};

const validationSchema = object({
  [RegisterFormNames.username]: string().required(),
  [RegisterFormNames.password]: string().required(),
  [RegisterFormNames.nationality]: string().required(),
  [RegisterFormNames.email]: string().required(),
  [RegisterFormNames.country]: string().required(),
  [RegisterFormNames.mobile]: string().required(),
  [RegisterFormNames.role]: string().required(),
  /* [RegisterFormNames.gender]: string(), */
  [RegisterFormNames.university]: string().required(),
});

export { initialValues, validationSchema };
