import { FC, useMemo } from "react";
import { Box, TextField, SxProps, TextFieldProps } from "@mui/material";
import styles from "./styles";
import { FormikValues, getIn, useFormikContext } from "formik";
import ErrorMessage from "common/error_message";

type Props = {
  extraStyles?: SxProps;
  name: string;
} & TextFieldProps;

const InputField: FC<Props> = ({ extraStyles, name, ...args }) => {
  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext();
  const errorText = getIn(touched, name) && getIn(errors, name);

  const containerStyle = useMemo(() => {
    return { ...styles.container, ...extraStyles };
  }, [extraStyles]);

  return (
    <Box sx={containerStyle}>
      <TextField
        data-testid="input-field-testid"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!errorText}
        value={(values as FormikValues)[name]}
        variant="outlined"
        sx={styles.input}
        {...args}
      />
      {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
    </Box>
  );
};

export default InputField;
