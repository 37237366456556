import { FC, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import styles from "./styles";
/* import DetailsPageHeader from "components/details_page_header"; */
/* import HeaderSection from "./header_section";
import BodySection from "./body_section"; */
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import updateMembership from "utils/update_membership";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { NavigationRoutes } from "navigation/routes";
import DetailsPageRow from "components/details_page_row";
import { useGetPodcastByIdQuery } from "store/api/podcast_service/endpoints";
/* import DetailsPageForm from "components/details_page_form";
import { initialValues, validationSchema } from "./form"; */

const PodcastDetails: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  const { data, isSuccess } = useGetPodcastByIdQuery(
    {
      id,
      token: access_token,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  if (!isSuccess && data == undefined) return null;

  const handleReject = async () => {
    try {
      await updateMembership(
        { status: UploadedFileStatus.REJECTED },
        { id: id!.toString(), token: access_token }
      );
      navigate(NavigationRoutes.speakers);
    } catch (error) {
      alert("Something went wrong ! try again later");
    }
  };

  const handleAccept = async () => {
    try {
      await updateMembership(
        { status: UploadedFileStatus.ACCEPTED },
        { id: id!.toString(), token: access_token }
      );
      navigate(NavigationRoutes.speakers);
    } catch (error) {
      alert("Something went wrong ! try again later");
    }
  };

  return (
    <Box sx={styles.container}>
      {/* <DetailsPageHeader pageLabel="Speaker" onDelete={handleDelete} /> */}
      <br />
      <DetailsPageRow pageLabel="Podcast Details" data={data} />
      <Stack direction="row" columnGap={2} marginBottom={5}>
        {data?.status == 0 && (
          <Button
            extraStyles={{ backgroundColor: color_pallette.iconTint }}
            label="Accept"
            onClick={handleAccept}
          />
        )}
        {data?.status == 0 && (
          <Button
            extraStyles={{ backgroundColor: color_pallette.danger }}
            label="Reject"
            onClick={handleReject}
          />
        )}
      </Stack>
      {/* <HeaderSection />
      <BodySection /> */}
      {/* <DetailsPageForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      /> */}
    </Box>
  );
};

export default PodcastDetails;
