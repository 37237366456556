import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import DateFilterSection from "./date_filter_section";
import HeaderSection from "./header_section";
import BodySection from "./body_section";
import FooterSection from "./footer_section";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const Dashboard = () => {
  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);
  }, [access_token, navigate]);

  return (
    <Box sx={styles.container}>
      <Grid container sx={styles.header}>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={8}>
          <Typography noWrap sx={styles.headerLabel}>
            <FormattedMessage id={messages.profileInsights.id} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
          <DateFilterSection />
        </Grid>
      </Grid>
      <HeaderSection />
      <BodySection />
      <FooterSection />
    </Box>
  );
};

export default Dashboard;
