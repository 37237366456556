import { FC, useEffect } from "react";
import { Divider, Grid, Link, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { NavigationRoutes } from "navigation/routes";
import color_pallette from "theme/color_pallette";
import cropText from "utils/crop_text";
import { propMapper } from "./prop_mapper";

type Props = {
  data: Record<string, string>;
  pageLabel: string;
};

const DetailsPageRow: FC<Props> = ({ data, pageLabel }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const getValue = (entry: string) => {
    if (data[entry] == null) return <Typography>Not Specified</Typography>;

    const val = String(data[entry]);

    const highlight = val.startsWith("https");

    return (
      <>
        {highlight ? (
          <Link href={val} target="_blank">
            <Typography
              sx={{
                color: color_pallette.iconTint,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {cropText(val, 50)}
            </Typography>
          </Link>
        ) : (
          <Typography>{cropText(val, 50)}</Typography>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!id) navigate(NavigationRoutes.online);
    if (!access_token) navigate(NavigationRoutes.login);
  }, [access_token, navigate, id]);

  if (!data) return <Typography>Details Not Available !</Typography>;

  return (
    <Stack marginBottom={10}>
      <Typography
        sx={{ fontSize: { lg: 25 }, fontWeight: "500", marginBottom: 5 }}
      >
        {pageLabel}
      </Typography>
      <Grid justifyContent="stretch" container rowGap={1} sx={styles.container}>
        {data &&
          Object.keys(data).map((entry, index) => (
            <Grid
              item
              lg={6}
              sx={{
                height: 50,
                backgroundColor:
                  index % 2 === 0 ? color_pallette.backgroundGray : "white",
                paddingInline: 3,
              }}
            >
              <Divider orientation="horizontal" />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: "100%",
                  whiteSpace: "nowrap",
                }}
                key={entry}
              >
                <Typography sx={{ fontWeight: "700" }}>
                  {(propMapper as Record<string, string>)[entry]}
                </Typography>
                <Divider orientation="vertical" />
                <>{getValue(entry)}</>
              </Stack>
              <Divider orientation="horizontal" />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default DetailsPageRow;
