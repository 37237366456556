import { defineMessages } from "react-intl";

const scope = "LoginPage";

export default defineMessages({
  loginLabel: {
    id: `${scope}.loginLabel`,
    defaultMessage: "Login",
  },
  orLabel: {
    id: `${scope}.orLabel`,
    defaultMessage: "OR",
  },
});
