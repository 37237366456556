import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBottom: 10,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "center",
    columnGap: {
      md: 2,
    },
    paddingInline: {
      md: 2,
    },
    width: {
      xl: "100%",
    },
  },
};

    export default styles;