import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Speaker } from "types/speaker";
import { rows } from "pages/speakers/data";

type SpeakerState = Array<Speaker>;

const initialState: SpeakerState = rows;

const speakerSlice = createSlice({
  name: "speaker_slice",
  initialState,
  reducers: {
    submitSpeakers: (
      state: SpeakerState,
      { payload }: PayloadAction<Array<Speaker>>
    ) => {
      state = payload;
      return state;
    },
    createSpeaker: (
      state: SpeakerState,
      { payload }: PayloadAction<Speaker>
    ) => {
      state.push(payload);
      return state;
    },
    deleteSpeaker: (
      state: SpeakerState,
      { payload }: PayloadAction<Speaker>
    ) => {
      return state.filter((speaker) => speaker.id !== payload.id);
    },
    updateSpeaker: (
      state: SpeakerState,
      { payload }: PayloadAction<Speaker>
    ) => {
      const index = state.findIndex((speaker) => speaker.id === payload.id);
      state[index] = { ...payload };
      return state;
    },
  },
});

export default speakerSlice.reducer;
export const speakerActions = speakerSlice.actions;
