import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type RejectPayload = {
  show: boolean;
  id: string | number;
};

const initialState: RejectPayload = {
  id: "",
  show: false,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleRejectModal: (
      state: RejectPayload,
      { payload }: PayloadAction<RejectPayload>
    ) => {
      state = payload;
      if (!state.show) state.id = "";
      return state;
    },
  },
});

export default modalSlice.reducer;
export const modalSliceActions = modalSlice.actions;
