import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 10,
    justifyContent: "space-between",
    paddingBottom: 20,
  },
};

export default styles;