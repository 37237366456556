import { FC } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import styles from "./styles";
import FormContainer from "common/form_container";
import InputField from "common/input_field";
import SelectInputField from "common/select_input_field";
import { countryList } from "pages/admins/register_form/data";
import SubmitButton from "common/submit_button";
import color_pallette from "theme/color_pallette";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useRegisterUserMutation } from "store/api/auth_service/endpoints";
import { Request } from "store/api/types";
import {
  validationSchema,
  initialValues,
  RegisterFormNames,
} from "./form_data";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import useSnack from "hooks/useSnack";

const RegisterForm: FC = () => {
  const navigate = useNavigate();

  const [register] = useRegisterUserMutation();

  const dispatch = useAppDispatch();

  const isAdminEmail = (email: string): boolean => {
    return email.includes("@gov.ae");
  };

  const { triggerSnack } = useSnack();

  const handleSubmit = async (values: Request) => {
    if (isAdminEmail(values.email)) {
      const { error } = (await register(values)) as {
        error: { data: string; originalStatus: number };
      };
      if (error.originalStatus === 200) {
        dispatch(globalSliceActions.submitUserTransactionId(error.data));
        navigate(NavigationRoutes.login);
      }
    } else {
      triggerSnack("Access Restricted", "error");
    }
  };

  const handleLogin = () => {
    navigate(NavigationRoutes.login);
  };

  return (
    <Box sx={styles.container}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Grid justifyContent="center" container rowGap={2} columnGap={1.5}>
          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label="Full name"
              placeholder="Username"
              name={RegisterFormNames.username}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="email"
              label="Email"
              name={RegisterFormNames.email}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label="Phone Number"
              placeholder="E.g 581196897"
              name={RegisterFormNames.mobile}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <SelectInputField
              label="Country"
              inputMode="search"
              placeholder="Choose Country"
              name={RegisterFormNames.country}
              dataList={countryList}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label="University / Organization"
              placeholder="Univerisity"
              name={RegisterFormNames.university}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <SelectInputField
              label="Nationality"
              inputMode="search"
              placeholder="Nationality"
              name={RegisterFormNames.nationality}
              dataList={countryList}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label="Password"
              type="password"
              placeholder="Choose Password"
              name={RegisterFormNames.password}
            />
          </Grid>
        </Grid>
        <Stack
          alignItems="center"
          direction="column"
          sx={styles.buttonContainer}
        >
          <SubmitButton extraStyles={styles.button} title="Register" />
          <Typography onClick={() => handleLogin()} sx={styles.loginLabel}>
            Already Have an account ?{" "}
            <span
              style={{
                color: color_pallette.iconTint,
                cursor: "pointer",
              }}
            >
              Login
            </span>
          </Typography>
        </Stack>
      </FormContainer>
    </Box>
  );
};

export default RegisterForm;
