import { VideoQuery } from "types/video_query";
import { NAMESPACES } from "./namespaces";
import { MembershipQuery } from "types/membershipQuery";
import { UploadPrivacy } from "types/privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";

const apiUrls = {
  getOnlineDetails: (id: number) => `online/${id}`,
  getOnlineEvents: () => `online`,

  addCategory: () => `${NAMESPACES.category}/addCustomerCats`,
  findAllCategories: () => `${NAMESPACES.category}/findAll`,
  getCustomer: (id: string) => `${NAMESPACES.category}/getCustomer/${id}`,

  fineAllUser: () => `${NAMESPACES.user}/findAll`,
  registerUser: () => `${NAMESPACES.user}/reg`,
  userById: (id: string) => `${NAMESPACES.user}/user/${id}`,
  validateOTP: () => `${NAMESPACES.user}/validate`,

  resetPwd: () => `${NAMESPACES.identity}/resetPass`,
  preResetPwd: () => `${NAMESPACES.identity}/preResetPass`,
  generateToken: () => `${NAMESPACES.identity}/token/generate`,
  refreshToken: () => `${NAMESPACES.identity}/token/refresh`,
  verifyToken: () => `${NAMESPACES.identity}/token/verify`,
  getRoles: () => `${NAMESPACES.identity}/roles`,

  getMembershipList: ({ status, category }: Partial<MembershipQuery>) =>
    `${NAMESPACES.speaker}/membership/?${status ? `status=${status}` : ``}${
      category ? `${status ? `&` : ``}category=${category}` : ``
    }`,

  postMembership: () => `${NAMESPACES.speaker}/membership/`,

  membershipById: (id: string | number) =>
    `${NAMESPACES.speaker}/membership/${id}/`,

  queryMembershipById: (id: string) =>
    `${NAMESPACES.speaker}/membership/${id}/`,

  healthCheck: () => `${NAMESPACES.video}/on_demand/health_check`,
  createVideo: () => `${NAMESPACES.video}/on_demand/`,
  videoById: (id?: string) => `${NAMESPACES.video}/on_demand/${id}/`,

  getVideoList: ({ status, category, privacy }: VideoQuery) =>
    `${NAMESPACES.video}/on_demand/all?privacy=${
      privacy ? privacy : UploadPrivacy.PUBLIC
    }&status=${status ? status : UploadedFileStatus.PENDING}&category=${
      category ? category : "1"
    }`,

  podcastHealthCheck: () => `${NAMESPACES.podcast}/health_check`,
  createPodcast: () => `${NAMESPACES.podcast}/on_demand/`,
  podcastById: (id?: string) => `${NAMESPACES.podcast}/on_demand/${id}/`,

  getPodcastList: ({ status, category, privacy }: VideoQuery) =>
    `${NAMESPACES.podcast}/on_demand/all?privacy=${
      privacy ? privacy : UploadPrivacy.PUBLIC
    }&status=${status ? status : UploadedFileStatus.PENDING}&category=${
      category ? category : "1"
    }`,
};

export default apiUrls;

/* 
getVideoList: ({
    user,
    category,
    title,
    description,
    privacy,
    status,
    page,
  }: VideoQuery) =>
    `${NAMESPACES.video}/on_demand/?user=${user}&category=${category}&title=${title}&description=${description}&privacy=${privacy}&status=${status}&page=${page}`,
*/
