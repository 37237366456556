import apiUrls from "store/api/urls";
import axios from "axios";
import { MembershipQuery } from "types/membershipQuery";
import { MembershipServiceResponse } from "types/membership_service_response";
import { DisplayedSpeaker } from "types/displayed_speaker";

const fetchMembersDashboardList = async (
  param: MembershipQuery
): Promise<{ data: Array<DisplayedSpeaker>; statusCode: number }> => {
  const apiResult = await axios.get(apiUrls.getMembershipList({ ...param }), {
    baseURL: "https://api.livetips.crowddp.com/",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      Authorization: `Bearer ${param.token}`,
    },
  });

  const res: Array<DisplayedSpeaker> = apiResult.data.results.map(
    (entry: MembershipServiceResponse) => ({
      name: entry.user.name,
      views: "0",
      profile_picture: entry.profile_picture,
      id: entry.user.customerId.toString(),
    })
  );
  return { data: res, statusCode: apiResult.status };
};

export default fetchMembersDashboardList;
