import { Methods } from "types/http";
import { Authorization, Request, UserQuery, Validation } from "../types";
import apiUrls from "../urls";

export const getUserById = ({ token, id }: UserQuery) => ({
  url: apiUrls.userById(id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
    Accept: "*/*",
  },
});

export const refreshTokenAction = (body: Authorization) => ({
  url: apiUrls.refreshToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const submitRegisterCredentials = (body: Request) => ({
  url: apiUrls.registerUser(),
  method: Methods.POST,
  body: {
    name: body.name,
    nationality: body.nationality,
    country: body.country,
    email: body.email,
    mobile: body.mobile,
    org: body.org,
    passWord: body.passWord,
    role: "admin",
    gender: body.gender,
  },
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const validateUserCredentials = (body: Validation) => ({
  url: apiUrls.validateOTP(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const findAllUsersAction = () => ({
  url: apiUrls.fineAllUser(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});
