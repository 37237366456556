import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { useFindAllUsersQuery } from "store/api/auth_service/endpoints";
import Nominee from "components/nominee";

const AdminListing: FC = () => {
  const { data } = useFindAllUsersQuery();

  const admins = data && data.filter((entry) => entry.role == "admin");

  return (
    <Box sx={styles.container}>
      {admins && admins.length > 0 ? (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {admins.map((entry) => (
            <Nominee {...entry} />
          ))}
        </Stack>
      ) : (
        <Typography
          sx={{
            fontSize: {
              lg: 35,
            },
            fontWeight: "600",
          }}
        >
          NO ADMIN WERE FOUND AT THIS MOMENT
        </Typography>
      )}
    </Box>
  );
};

export default AdminListing;
