import { FC } from "react";
import { Modal, Paper } from "@mui/material";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import FormContainer from "common/form_container";
import SubmitButton from "common/submit_button";
import Button from "components/custom_button";
import RejectModalForm from "./form";
import { initialValues, validationSchema } from "./form_props";
import { modalSliceActions } from "store/modals_slice";

type Props = {
  postRejectAction?: (id: string | number) => void;
};

const RejectionModal: FC<Props> = ({ postRejectAction }) => {
  const dispatch = useAppDispatch();

  const { show, id } = useAppSelector((state) => state.modals);

  const handleSubmitRejection = async () => {
    if (postRejectAction) postRejectAction(id);
    dispatch(modalSliceActions.toggleRejectModal({ show: false, id }));
  };

  return (
    <Modal sx={styles.modal} open={show}>
      <Paper sx={styles.container}>
        <FormContainer
          initialValues={initialValues}
          onSubmit={handleSubmitRejection}
          validationSchema={validationSchema}
        >
          <RejectModalForm />
          <SubmitButton
            extraStyles={{ width: 200, paddingBlock: 1 }}
            title="Submit"
          />
          <Button label="Cancel" extraStyles={styles.cancelButton} />
        </FormContainer>
      </Paper>
    </Modal>
  );
};

export default RejectionModal;
