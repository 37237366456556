import { defineMessages } from "react-intl";

const scope = "Services";

export default defineMessages({
  blog: {
    id: `${scope}.blog`,
    defaultMessage: "Blog",
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  podcast: {
    id: `${scope}.podcast`,
    defaultMessage: "Podcast",
  },
  live: {
    id: `${scope}.live`,
    defaultMessage: "Interactive",
  },
  online: {
    id: `${scope}.online`,
    defaultMessage: "Online",
  },
  speakers: {
    id: `${scope}.speakers`,
    defaultMessage: "Speakers",
  },
  insight: {
    id: `${scope}.insight`,
    defaultMessage: "Insight",
  },
});
