import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
/* import DetailsPageHeader from "components/details_page_header";
import messages from "./messages"; */
/* import DetailsPageForm from "components/details_page_form";
import { initialValues, validationSchema } from "./form"; */
import OnlineVideoDetails from "pages/online_video_details";

const OnlineDetails: FC = () => {
  /* const handleDelete = () => {}; */

  return (
    <Box sx={styles.container}>
      {/* <DetailsPageHeader
        onDelete={handleDelete}
        pageLabel={messages.videoDetails.id}
      /> */}
      <OnlineVideoDetails />
      {/* <DetailsPageForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmitForm}
      /> */}
    </Box>
  );
};

export default OnlineDetails;
