import { Box } from "@mui/material";
import useDateRangePicker from "hooks/useDateRangePicker";
import { FC } from "react";
import { DateRangePicker } from "react-dates";
import { useIntl } from "react-intl";
import { DATES } from "types/date_picker";
import messages from "../messages";

const DateFilterSection: FC = () => {
  const intl = useIntl();

  const { startDate, endDate, focused, setFocused, setDate } =
    useDateRangePicker();

  return (
    <Box>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        startDatePlaceholderText={intl.formatMessage(messages.startDate)}
        endDatePlaceholderText={intl.formatMessage(messages.endDate)}
        startDateId={DATES.startDate}
        endDateId={DATES.endDate}
        showDefaultInputIcon
        small
        keepFocusOnInput
        /* withPortal */
        /* isRTL */
        onDatesChange={(date) => setDate({ ...date })}
        focusedInput={focused.focused}
        onFocusChange={(focused) => setFocused({ focused })}
      />
    </Box>
  );
};

export default DateFilterSection;
