import { FC } from "react";
import { Box, IconButton, Toolbar } from "@mui/material";
import StyledAppBar from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SidebarAction } from "store/side_bar_slice";
import { Local } from "types/local";
import { globalSliceActions } from "store/global_slice";
import Menu from "common/menu";
import livetips from "assets/images/live_tips.png";

const AppBar: FC = () => {
  const open = useAppSelector((state) => state.sidebar.open);
  const dispatch = useAppDispatch();

  const handleDrawerOpen = () => {
    dispatch(SidebarAction.toggleSidebar());
  };

  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar sx={{ backgroundColor: "white", height: 90 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            color: "black",
            marginInlineEnd: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            srcSet={livetips}
            src={livetips}
            loading="lazy"
            style={{
              maxHeight: 70,
              width: 150,
            }}
          />
          <Menu
            menuLabel="Languages"
            menuItems={[
              {
                label: Local.Arabic,
                handleClick: () =>
                  dispatch(globalSliceActions.changeLanguage(Local.Arabic)),
              },
              {
                label: Local.English,
                handleClick: () =>
                  dispatch(globalSliceActions.changeLanguage(Local.English)),
              },
            ]}
          />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;
