import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  menuButton: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingInlineEnd: 2,
  },
  iconSize: { height: 18, width: 18 },
};

export default styles;
