import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer",
  },
  headerLabel: { fontSize: 15 },
  rating: { color: color_pallette.iconTint, marginBlock: 0.5 },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginInlineStart: 2,
  },
  views: {
    fontSize: 15,
    fontWeight: "bold",
  },
};

export default styles;
