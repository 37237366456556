import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Card from "components/card";
import LoginForm from "./login_form";
import UaePassButton from "common/uae_pass_button";
import styles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import Seperator from "common/seperator";
import { useAppSelector } from "store/hooks";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import SnackBar from "components/snack_bar";

const LoginPage: FC = () => {
  const intl = useIntl();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (access_token) navigate(NavigationRoutes.dashboard);
  }, [access_token, navigate]);

  return (
    <>
      <Box sx={styles.container}>
        <Card extraStyle={styles.cardStyle}>
          <Typography sx={styles.font}>
            {intl.formatMessage(messages.loginLabel)}
          </Typography>
          <LoginForm />
          <Box sx={styles.orSection}>
            <Seperator />
            <Typography sx={styles.orLabel}>
              {intl.formatMessage(messages.orLabel)}
            </Typography>
            <Seperator />
            <SnackBar />
          </Box>
          <Box sx={styles.center}>
            <UaePassButton />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default LoginPage;
