import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "components/drawer";
import AppBar from "components/app_bar";
import DrawerHeader from "components/drawer_header";
import { FC, PropsWithChildren } from "react";
import { useAppSelector } from "store/hooks";

const ContainerLayout: FC<PropsWithChildren<NonNullable<unknown>>> = ({
  children,
}) => {
  const { sidebar, global } = useAppSelector((state) => state);
  return (
    <Box
      component="div"
      dir={global.lang.direction}
      sx={{
        display: "flex",
        width: `calc(100% - ${sidebar.open ? 250 : 20})`,
        marginInlineStart: 5,
        marginInlineEnd: 15,
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <AppBar />
      <Drawer />
      <Box component="main" sx={{ marginTop: 5, flexGrow: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default ContainerLayout;
