import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Data } from "components/table/data";

type SpeakerState = Array<Data>;

const initialState: SpeakerState = [];

const videoSlice = createSlice({
  name: "video_slice",
  initialState,
  reducers: {
    submitVideos: (
      state: SpeakerState,
      { payload }: PayloadAction<Array<Data>>
    ) => {
      state = payload;
      return state;
    },
  },
});

export default videoSlice.reducer;
export const videoActions = videoSlice.actions;
