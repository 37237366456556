import Service from "services/Service";
import { Icon } from "../types/icon";
import { ImageRatio } from "../types/image_ratio";
import { ServiceGroup } from "services/types/service_group";
import { ServiceRoute } from "services/types/service_route";
import { ServiceName } from "services/types/service_name";
import { ServiceContentPage } from "services/types/service_content_page";

class OnlineService extends Service {
  constructor(
    serviceName: ServiceName,
    serviceRoute: ServiceRoute,
    serviceGroup: ServiceGroup,
    imageRatio: ImageRatio,
    Component: Icon,
    serviceContentPage: ServiceContentPage,
    serviceDetailsPage: ServiceContentPage
  ) {
    super(
      serviceName,
      serviceRoute,
      serviceGroup,
      imageRatio,
      Component,
      serviceContentPage,
      serviceDetailsPage
    );
  }

  override cropImage(): void {
    super.cropImage;
  }

  post(): void {}
  get(): void {}
  getAll(): void {}
  delete(): void {}
  update(): void {}
  patch(): void {}
}

export default OnlineService;
