import { WithId } from "types/table_generic";

export interface Data extends WithId {
  name: string;
  org: string;
  title: string;
  status: string;
}

function createData(
  id: number,
  name: string,
  org: string,
  title: string,
  status: string
): Data {
  return {
    id,
    name,
    org,
    title,
    status,
  };
}

export const rows = [
  createData(1, "Cupcake", "305", "3.7,", "67"),
  createData(2, "Donut", "452", "25.0", "51"),
  createData(3, "Eclair", "262", "16.0", "24"),
  createData(4, "Frozen yoghurt", "159", "6.0,", "24"),
  createData(5, "Gingerbread", "356", "16.0", "49"),
  createData(6, "Honeycomb", "408", "3.2,", "87"),
  createData(7, "Ice cream sandwich", "237", "9.0,", "37"),
  createData(8, "Jelly Bean", "375", "0.0,", "94"),
  createData(9, "KitKat", "518", "26.0", "65"),
  createData(10, "Lollipop", "392", "0.2,", "98"),
  createData(11, "Marshmallow", "318", "0, 8", "1"),
  createData(12, "Nougat", "360", "19.0", "9"),
  createData(13, "Oreo", "437", "18.0", "63"),
];

export const headers = Object.keys(rows[0]) as Array<keyof Data>;