import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import Table from "components/table";
import { headers, Data } from "components/table/data";
import useOnlineVideos from "hooks/use_online_videos";
import FilterContext from "context/filter_context";
import RejectionModal from "components/rejection_modal";

const Online: FC = () => {
  const {
    data,
    handleItemAccept,
    status,
    setCategroy,
    setStatus,
    category,
    privacy,
    setPrivacy,
    handleItemReject,
  } = useOnlineVideos();

  return (
    <Box sx={styles.container}>
      <FilterContext.Provider
        value={{
          category,
          privacy,
          setPrivacy,
          setCategroy,
          status,
          setStatus,
        }}
      >
        <RejectionModal postRejectAction={handleItemReject} />
        <Table<Data>
          rows={data}
          handleAccept={handleItemAccept}
          handleReject={handleItemReject}
          headers={headers}
          tableLabel={"Live Tips Online Videos"}
        />
      </FilterContext.Provider>
    </Box>
  );
};

export default Online;
