import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import Table from "components/table";
import { Speaker } from "types/speaker";
import { Event } from "types/table_event";
import { headers } from "./data";
import { useAppDispatch, useAppSelector } from "store/hooks";
import updateMembership from "utils/update_membership";
import { UploadedFileStatus } from "types/uploaded_file_status";
import fetchMembersList from "utils/get_members";
import { speakerActions } from "store/speaker_slice";
import FilterContext from "context/filter_context";
import { speakerClassData } from "components/filters_modal/form";
import { useRefreshTokenMutation } from "store/api/auth_service/endpoints";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { UploadPrivacy } from "types/privacy";

const Speakers: FC = () => {
  const [category, setCategroy] = useState<number>();
  const [status, setStatus] = useState<UploadedFileStatus>();
  const [privacy, setPrivacy] = useState<UploadPrivacy>();

  const data = useAppSelector((state) => state.speaker);

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [refresh] = useRefreshTokenMutation();

  const fetchSpeakers = async () => {
    const { data, statusCode } = await fetchMembersList({
      token: access_token,
      category:
        category && category !== speakerClassData[0].value
          ? category.toString()
          : undefined,
      status: status && status !== UploadedFileStatus.ALL ? status : undefined,
    });
    if (statusCode === 401) {
      refresh({ token: access_token });
      await fetchSpeakers();
    }
    dispatch(speakerActions.submitSpeakers(data));
  };

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);

    fetchSpeakers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, category]);

  const onReject: Event = async (id: string | number) => {
    await updateMembership(
      { status: UploadedFileStatus.REJECTED },
      { id, token: access_token }
    );
    fetchSpeakers();
  };

  const onAccept: Event = async (id: string | number) => {
    await updateMembership(
      { status: UploadedFileStatus.ACCEPTED },
      { id, token: access_token }
    );
    fetchSpeakers();
  };

  return (
    <Box sx={styles.container}>
      <FilterContext.Provider
        value={{
          category,
          privacy,
          setPrivacy,
          setCategroy,
          setStatus,
          status,
        }}
      >
        <Table<Speaker>
          rows={data}
          handleAccept={onAccept}
          handleReject={onReject}
          headers={headers}
          tableLabel="Live Tips Speakers"
        />
      </FilterContext.Provider>
    </Box>
  );
};

export default Speakers;
