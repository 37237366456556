import { createSlice } from "@reduxjs/toolkit";

type State = {
  open: boolean;
};

const initialState: State = {
  open: false,
};

const sideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state: State) => {
      state.open = !state.open;
      return state;
    },
  },
});

export default sideBarSlice.reducer;
export const SidebarAction = sideBarSlice.actions;
