import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerLabel: {
    fontSize: {
      xs: 30,
      md: 33,
    },
    color: color_pallette.primary,
    mb: {
      xs: 5,
      md: 0,
    },
  },
  header: {
    mt: 2,
    width: "100%",
    justifyContent: "space-between",
  },
};

export default styles;
