import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import styles from "./styles";
import MostViewedSection from "../most_viewed_section";
import OptionsHeader from "common/options_header";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Table from "components/table";
import { Data, headers } from "components/table/data";
import useOnlineVideos from "hooks/use_online_videos";
import { NavigationRoutes } from "navigation/routes";

const FooterSection: FC = () => {
  const { handleItemAccept, handleItemReject, data } = useOnlineVideos();

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
        <OptionsHeader
          extraStyles={{ marginBottom: 5 }}
          menuOptions={[{ label: messages.plans.id, handleClick: () => {} }]}
        >
          <Typography sx={{ fontWeight: "bold", opacity: 0.7 }}>
            <FormattedMessage id={messages.recentUploads.id} />
          </Typography>
        </OptionsHeader>
        <Table<Data>
          rows={data ?? []}
          rootPath={NavigationRoutes.online}
          handleAccept={handleItemAccept}
          handleReject={handleItemReject}
          headers={headers}
          tableLabel={"Online Videos"}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <OptionsHeader
          extraStyles={{ marginBottom: 5 }}
          menuOptions={[{ label: messages.plans.id, handleClick: () => {} }]}
        >
          <Typography sx={{ fontWeight: "bold", opacity: 0.7 }}>
            <FormattedMessage id={messages.mostViewed.id} />
          </Typography>
        </OptionsHeader>
        <MostViewedSection />
      </Grid>
    </Grid>
  );
};

export default FooterSection;