import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    height: {
      xs: "95%",
      sm: "95%",
      md: "80%",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  headerContainer: {
    background: "linear-gradient(338.62deg, #FFFFFF -82.03%, #BCE0E1 69.91%)",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    marginX: "auto",
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
      lg: "100%",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBlock: 2,
  },
  orSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingY: 3,
  },
  font: {
    fontWeight: "700",
  },
  cardStyle: {
    height: {
      sm: "100%",
      md: "80%",
    },
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
      lg: "100%",
    },
    backgroundColor: "#F3F3F3",
  },
  orLabel: { marginX: 5, opacity: 0.6, textAlign: "center" },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
