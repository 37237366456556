import { FC } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import color_pallette from "theme/color_pallette";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 230 },
];

const COLORS = [
  color_pallette.iconTint,
  color_pallette.primary,
  color_pallette.iconTint,
  "darkcyan",
];

const PieChartSection: FC = () => {
  return (
    <PieChart width={800} height={400}>
      <Pie
        data={data}
        cx={300}
        cy={160}
        innerRadius={80}
        outerRadius={150}
        fill="#8884d8"
        paddingAngle={2}
        dataKey="value"
      >
        {data.map((_, index) => (
          <Cell
            radius={20}
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
      <Legend verticalAlign="bottom" align="center" layout="horizontal" />
    </PieChart>
  );
};

export default PieChartSection;
