import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grow from "@mui/material/Grow";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import styles from "./styles";
import { FlattenUser } from "store/api/types";

const Nominee: FC<FlattenUser> = (props) => {
  console.log(props);
  return (
    <Grow in timeout={700}>
      <Box sx={styles.container}>
        <Avatar sx={styles.img} src={props.profilePicturePath ?? thumbNail} />
        <Typography sx={styles.header}>{props.name}</Typography>
        <Typography sx={styles.subHeader}>{props.org}</Typography>
      </Box>
    </Grow>
  );
};

export default Nominee;
