import { Icon } from "./types/icon";
import { ImageRatio } from "./types/image_ratio";
import { ServiceContentPage } from "./types/service_content_page";
import { ServiceGroup } from "./types/service_group";
import { ServiceName } from "./types/service_name";
import { ServiceRoute } from "./types/service_route";

type ServiceList = Array<{ service: Service; display: Display }>;

type Display = "nested" | "main_route";

/* DECLARED abstract to prevent instantiation */
abstract class Service {
  private static SERVICES_LIST: ServiceList = [];

  private serviceName: ServiceName;
  private serviceRoute: ServiceRoute;
  private imageRatio: ImageRatio;
  private Component: Icon;
  private serviceGroup: ServiceGroup;
  private serviceContentPage: ServiceContentPage;
  private serviceDetailsPage: ServiceContentPage;

  constructor(
    serviceName: ServiceName,
    serviceRoute: ServiceRoute,
    serviceGroup: ServiceGroup,
    imageRatio: ImageRatio,
    Component: Icon,
    serviceContentPage: ServiceContentPage,
    serviceDetailsPage: ServiceContentPage
  ) {
    this.serviceName = serviceName;
    this.imageRatio = imageRatio;
    this.serviceGroup = serviceGroup;
    this.serviceRoute = serviceRoute;
    this.Component = Component;
    this.serviceContentPage = serviceContentPage;
    this.serviceDetailsPage = serviceDetailsPage;
  }

  static addService(service: Service, display: Display = "main_route") {
    Service.SERVICES_LIST.push({ service, display });
  }

  getContentPage(): ServiceContentPage {
    return this.serviceContentPage;
  }

  static getServices(): ServiceList {
    return Service.SERVICES_LIST;
  }

  getDetailsPage(): ServiceContentPage {
    return this.serviceDetailsPage;
  }

  protected cropImage(): void {
    /* LOGIC OF CROPPED IMAGES HERE... */
  }

  getComponent(): Icon {
    return this.Component;
  }

  getServiceGroup(): ServiceGroup {
    return this.serviceGroup;
  }

  getServiceRoute(): string {
    return this.serviceRoute;
  }

  getImageRatio(): ImageRatio {
    return this.imageRatio;
  }

  getServiceName(): string {
    return this.serviceName;
  }

  abstract delete(): void;
  abstract post(): void;
  /* for details page... */
  abstract get(): void;
  /* for table display */
  abstract getAll(): void;
  abstract update(): void;
  abstract patch(): void;
}

export default Service;
