import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginX: "auto",
    overflow: "hidden",
    paddingBlock: {
      xs: 3,
    },
    width: {
      xs: "90%",
    },
  },
  videoContainer: {
    padding: 3,
    backgroundColor: color_pallette.backgroundGray,
    width: "90%",
    height: { xs: 300, md: 450 },
    position: "relative",
    paddingTop: "51.25%",
  },
  notifyButton: {
    borderRadius: 10,
    fontSize: {
      xs: 12,
    },
    marginInlineStart: 2,
  },
  avatar: {
    width: 35,
    height: 35,
  },
  speaker: {
    fontWeight: "700",
  },
  datePublished: {
    opacity: 0.7,
    marginBottom: 1,
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
    marginTop: {
      xs: 1,
      lg: 3,
    },
  },
  statsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "100%",
    justifyContent: { lg: "space-between" },
    alignItems: "center",
    rowGap: 2,
    mt: 3,
  },
};

export default styles;
