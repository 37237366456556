import { defineMessages } from 'react-intl';

const scope = 'FooterSection';

export default defineMessages({
  recentUploads: {
    id: `${scope}.recentUploads`,
    defaultMessage: "Recent Uploads",
  },
  mostViewed: {
    id: `${scope}.mostViewed`,
    defaultMessage: "Most Viewed",
  },
  plans: {
    id: `${scope}.plans`,
    defaultMessage: "Plans",
  },
});