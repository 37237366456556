import { useState } from "react";
import { Calendar, DATES, FocusedInput } from "types/date_picker";

const useDateRangePicker = () => {
  const [date, setDate] = useState<Calendar>({
    [DATES.startDate]: null,
    [DATES.endDate]: null,
  });

  const [focused, setFocused] = useState<FocusedInput>({ focused: null });

  return {
    startDate: date.startDate,
    endDate: date.endDate,
    setDate,
    focused,
    setFocused,
  };
};

export default useDateRangePicker;
