import { FC } from "react";
import { Provider } from "react-redux";
import initStore from "./store";
import LanguageProvider from "translation/provider";
import { useAppSelector } from "store/hooks";
import { ThemeProvider } from "@mui/material";
import theme from "theme";
import RootNavigator from "navigation";
import "theme/date_picker_override.css";
import ContainerLayout from "components/container_layout";
import { Route, Routes } from "react-router-dom";
import Service from "services/Service";
import { PersistGate } from "redux-persist/integration/react";
import LoginPage from "pages/login_page";
import { NavigationRoutes } from "navigation/routes";
import RegisterPage from "pages/register_page";

const services = Service.getServices();

const Root: FC = () => {
  const { lang } = useAppSelector((state) => state.global);

  return (
    <>
      <LanguageProvider local={lang.prefix}>
        <ContainerLayout>
          <Routes>
            <Route element={<LoginPage />} path={NavigationRoutes.login} />
            <Route element={<RegisterPage />} path={NavigationRoutes.signup} />
            {services.map(({ service }) => (
              <>
                <Route
                  element={service.getContentPage()}
                  path={service.getServiceRoute()}
                />
                {service.getDetailsPage !== null ? (
                  <Route
                    element={service.getDetailsPage()}
                    path={`${service.getServiceRoute()}/:id`}
                  />
                ) : null}
              </>
            ))}
          </Routes>
        </ContainerLayout>
      </LanguageProvider>
    </>
  );
};

const App: FC = () => {
  const { store, persistor } = initStore();
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <RootNavigator>
            <Root />
          </RootNavigator>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
