import { FC, useMemo, useState } from "react";
import { Box, Button, MenuItem, Menu as MuiMenu } from "@mui/material";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import { FormattedMessage } from "react-intl";
import { MenuListItem } from "types/menu_list_item";
import more from "assets/svg/more.svg";

type Props = {
  menuItems: Array<MenuListItem>;
  menuLabel?: string;
};

const Menu: FC<Props> = ({ menuItems, menuLabel }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const open = useMemo(() => {
    return Boolean(anchor);
  }, [anchor]);

  const handleMenuItemClick = (entry: MenuListItem) => {
    /* do stuff here... */
    entry.handleClick();
    setAnchor(null);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  return (
    <Box data-testid="menu-component">
      {menuLabel ? (
        <Button onClick={handleButtonClick}>{menuLabel}</Button>
      ) : (
        <ReactSVG
          id="more-icon"
          data-testid="more-icon"
          src={more}
          onClick={(e) =>
            handleButtonClick(e as React.MouseEvent<HTMLButtonElement>)
          }
          style={{
            width: 30,
            height: 30,
            cursor: "pointer",
            opacity: 0.7,
          }}
        />
      )}
      <MuiMenu anchorEl={anchor} open={open} sx={styles.container}>
        {menuItems.map((entry) => (
          <MenuItem
            data-testid={entry.label}
            sx={{ opacity: 0.6 }}
            onClick={() => handleMenuItemClick(entry)}
            key={entry.label}
          >
            <FormattedMessage id={entry.label} />
          </MenuItem>
        ))}
      </MuiMenu>
    </Box>
  );
};

export default Menu;
