import { FC } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type Props = {
  orientation: "up" | "down";
};

const SortIcon: FC<Props> = ({ orientation = "down" }) => {
  return (
    <>
      {orientation === "down" ? (
        <ArrowDropDownIcon sx={{ width: 17, height: 17 }} />
      ) : (
        <ArrowDropUpIcon sx={{ width: 17, height: 17 }} />
      )}
    </>
  );
};

export default SortIcon;
