import { defineMessages } from 'react-intl';

const scope = 'BodySection';

export default defineMessages({
  reports: {
    id: `${scope}.reports`,
    defaultMessage: "Reports",
  },
  analytics: {
    id: `${scope}.analytics`,
    defaultMessage: "Analytics",
  },
  plans: {
    id: `${scope}.plans`,
    defaultMessage: "Plans",
  },
  community: {
    id: `${scope}.community`,
    defaultMessage: "Community",
  },
  view_stats: {
    id: `${scope}.view_stats`,
    defaultMessage: "View Stats",
  },
});