import { FC } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "store/hooks";
import Service from "services/Service";

type Props = {
  entry: Service;
  toggleCollapsable: () => void;
};

const MenuItemButton: FC<Props> = ({ entry, toggleCollapsable }) => {
  const { sidebar } = useAppSelector((state) => state);

  return (
    <ListItemButton
      onClick={() => toggleCollapsable()}
      sx={{
        minHeight: 48,
        justifyContent: sidebar.open ? "initial" : "center",
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: sidebar.open ? 3 : "auto",
          justifyContent: "center",
        }}
      >
        {entry.getComponent()}
      </ListItemIcon>
      <ListItemText sx={{ opacity: sidebar.open ? 1 : 0 }}>
        <FormattedMessage id={entry.getServiceName()} />
      </ListItemText>
    </ListItemButton>
  );
};

export default MenuItemButton;
