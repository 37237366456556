import { WithId } from "types/table_generic";

export function stableSort<T extends WithId>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const mutableSortedArray = array.map((el) => el as T);

  mutableSortedArray.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) {
      return order;
    }
    return -1;
  });

  return mutableSortedArray;
}
