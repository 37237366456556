import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import EnhancedTableToolbar from "components/table_toolbar";
import EnhancedTableHead from "components/table_head";
import EnhancedTableBody from "components/table_body";
import useTable from "hooks/useTable";
import { WithId } from "types/table_generic";
import { Event } from "types/table_event";
import { NavigationRoutes } from "navigation/routes";

type Props<T> = {
  tableLabel: string;
  rootPath?: NavigationRoutes;
  rows: Array<T>;
  headers: Array<keyof T>;
  handleAccept: Event;
  handleReject: Event;
};

function EnhancedTable<T extends WithId>({
  tableLabel,
  headers,
  handleAccept,
  handleReject,
  rows,
  rootPath,
}: Props<T>) {
  const {
    order,
    page,
    orderBy,
    selected,
    rowsPerPage,
    handleRequestSort,
    handleSelectAllClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
  } = useTable<T>();

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          messageId={tableLabel}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead<T>
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              headers={headers}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <EnhancedTableBody<T>
              page={page}
              rows={rows}
              headers={headers}
              handleAccept={handleAccept}
              handleReject={handleReject}
              selected={selected}
              onSelect={handleClick}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
              rootPath={rootPath}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default EnhancedTable;
