const refreshTokenMiddleware = (store: any) => (next: any) => (action: any) => {
  const { access_token, expires_in } =
    store.getState().global.credentials.token;

  const tokenExpired = resolveTokenExpiration(expires_in);

  if (!access_token && action.type == "authApi/refreshToken" && tokenExpired) {
    store.dispatch("authApi/refreshToken", {
      token: access_token,
    });
    console.log("TOKEN IS EXPIRED  ", tokenExpired);
  }

  return next(action);
};

const resolveTokenExpiration = (expiryDate: number): boolean => {
  if (expiryDate === 0) return true;
  return false;
  /* return Date.now() >= expiryDate; */
};

export default refreshTokenMiddleware;
