import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/theme/init_date_picker.css";
import "services/init_service";
import "react-dates/initialize";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);