export type RejectCategoryItem = {
  id: string | number;
  label: string;
  value: REJECTION_CATEGORY;
};

export enum REJECTION_CATEGORY {
  SPAM = "spam",
  IRRELAVANT = "irrelavant",
  UNPROPRIET = "unpropriet",
  SCAM = "scam",
}

const data: Array<RejectCategoryItem> = [
  { id: "1", label: "Spam", value: REJECTION_CATEGORY.SPAM },
  { id: "2", label: "Irrelavant", value: REJECTION_CATEGORY.IRRELAVANT },
  { id: "3", label: "Unpropriet", value: REJECTION_CATEGORY.UNPROPRIET },
  { id: "4", label: "Scam", value: REJECTION_CATEGORY.SCAM },
];

export default data;
