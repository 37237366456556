import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import MostViewedList from "components/most_viewed_list";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import fetchMembersDashboardList from "utils/normalize_dashboard_speaker";
import { DisplayedSpeaker } from "types/displayed_speaker";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useRefreshTokenMutation } from "store/api/auth_service/endpoints";

const MostViewedSection: FC = () => {
  const [data, setData] = useState<Array<DisplayedSpeaker>>([]);

  const { global } = useAppSelector((state) => state);

  const navigate = useNavigate();

  const [refresh] = useRefreshTokenMutation();

  const fetchSpeakers = async () => {
    const { statusCode, data } = await fetchMembersDashboardList({
      status: UploadedFileStatus.PENDING,
      token: global.credentials.token.access_token,
    });
    if (statusCode === 401) {
      refresh({ token: global.credentials.token.access_token });
      await fetchSpeakers();
    }
    setData(data.slice(0, 5));
  };

  useEffect(() => {
    fetchSpeakers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemDetails = (id: string) => {
    navigate(`${NavigationRoutes.speakers}/${id}`);
  };

  return (
    <Box sx={styles.container}>
      <MostViewedList onItemClick={handleItemDetails} data={data} />
    </Box>
  );
};

export default MostViewedSection;
