import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "85%",
      md: `100%`,
    },
    rowGap: {
      xs: 3,
      md: 2,
    },
    columnGap: {
      md: 2,
    },
    marginTop: 5,
  },
};

export default styles;
