import { FC } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const FormActionSection: FC = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate(NavigationRoutes.signup);
  };

  const handleForgotPassword = () => {
    navigate(NavigationRoutes.forgotPassword);
  };

  return (
    <Box sx={styles.flex}>
      <Box sx={styles.onHover} onClick={handleSignup}>
        <Typography sx={styles.textFormControlls}>
          Create an Account ?
        </Typography>
      </Box>
      <Box sx={styles.onHover} onClick={handleForgotPassword}>
        <Typography sx={styles.textFormControlls}>Forgot Password ?</Typography>
      </Box>
    </Box>
  );
};

export default FormActionSection;
