import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
  data: Array<Record<string, any>>;
  selectedItems: Array<Record<string, any>>;
};

const initialState: State = {
  data: [],
  selectedItems: [],
};

const tableSlice = createSlice({
  name: "table_slice",
  initialState,
  reducers: {
    createItem: (
      state: State,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.data.push(payload);
      return state;
    },
    selectItems: (
      state: State,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.selectedItems.push(payload);
      return state;
    },
    deleteMany: () => {},
  },
});

export default tableSlice.reducer;
export const tableActions = tableSlice.actions;

/* TODO: WHEN FETCHING FROM ANY API ENDPOINT , ADD ITEMS TO
        CORRESPONDING SLICE, AND TABLE
  */
