import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Data } from "components/table/data";

type SpeakerState = Array<Data>;

const initialState: SpeakerState = [];

const podcastSlice = createSlice({
  name: "podcast_slice",
  initialState,
  reducers: {
    submitPodcasts: (
      state: SpeakerState,
      { payload }: PayloadAction<Array<Data>>
    ) => {
      state = payload;
      return state;
    },
  },
});

export default podcastSlice.reducer;
export const podcastActions = podcastSlice.actions;
