import apiUrls from "store/api/urls";
import generateFormData from "./generate_form_data";
import axios from "axios";

type Param = {
  id: string | number;
  token: string;
};

const updateMembership = async (body: object, param: Param) => {
  const formData = generateFormData(body);

  const res = await axios.patch(
    apiUrls.membershipById(param.id.toString()),
    formData,
    {
      baseURL: "https://api.livetips.crowddp.com/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: `Bearer ${param.token}`,
      },
    }
  );
  return res.data;
};

export default updateMembership;
