import { FC, useEffect } from "react";
import styles from "./styles";
import {
  Avatar,
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "components/custom_button";
import { useAppSelector } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useGetVideoByIdQuery } from "store/api/video_service/endpoints";
import { NavigationRoutes } from "navigation/routes";
import color_pallette from "theme/color_pallette";
import ReactPlayer from "react-player";
import avatar from "assets/images/avatar_place.png";
import updateOnlineVideo from "utils/update_online_video";
import { UploadedFileStatus } from "types/uploaded_file_status";

const HeaderSection: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);
  }, [access_token, navigate]);

  const { data } = useGetVideoByIdQuery(
    {
      id,
      token: access_token,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const handleItemReject = async () => {
    try {
      await updateOnlineVideo(
        { status: UploadedFileStatus.REJECTED },
        {
          id: data!.id,
          token: access_token,
        }
      );
      navigate(NavigationRoutes.online);
    } catch (error) {
      alert("Error has occured , try again !");
    }
  };

  const handleItemAccept = async () => {
    try {
      await updateOnlineVideo(
        { status: UploadedFileStatus.ACCEPTED },
        {
          id: data!.id,
          token: access_token,
        }
      );
      navigate(NavigationRoutes.online);
    } catch (error) {
      alert("Error has occured , try again !");
    }
  };

  const handleViewProfile = (id: string | number) => {
    navigate(`${NavigationRoutes.speakers}/${id}`);
  };

  return (
    <Box sx={styles.container}>
      {upLg && (
        <Stack
          width="90%"
          marginTop={1}
          marginBottom={3}
          justifyContent="flex-start"
          direction="row"
          columnGap={2}
          alignItems="center"
        >
          <Typography sx={{ fontWeight: "700" }}>Publisher : </Typography>
          <Typography sx={{ color: color_pallette.iconTint }}>
            {data?.user.speaker.user.name}
          </Typography>
        </Stack>
      )}
      <Box sx={styles.videoContainer}>
        <ReactPlayer
          width="100%"
          height="100%"
          muted={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          playing={false}
          url={data?.media}
          controls
        />
      </Box>
      <Box sx={styles.flex}>
        <Stack
          onClick={() => handleViewProfile(data!.user.customerId)}
          direction="row"
          columnGap={1}
          sx={{ cursor: "pointer" }}
          alignItems="center"
        >
          <Avatar
            sx={styles.avatar}
            src={data?.user.speaker?.profile_picture ?? avatar}
          />
          <Typography sx={styles.speaker}>
            {data?.user.speaker.user.name}
          </Typography>
        </Stack>
        <Box textAlign="start" mt={1.5}>
          <Typography sx={styles.speaker}>{data?.title}</Typography>
          <Typography sx={styles.datePublished}>1 days ago</Typography>
          <Typography sx={{ maxWidth: "80%" }}>{data?.description}</Typography>
        </Box>
        <Stack sx={styles.statsContainer}>
          <Box>
            {data?.status === 0 && (
              <Button
                extraStyles={{
                  marginInline: 2,
                  backgroundColor: color_pallette.iconTint,
                }}
                onClick={handleItemAccept}
                label="Accept"
              />
            )}
            {data?.status === 0 && (
              <Button
                extraStyles={{
                  marginInline: 2,
                  backgroundColor: color_pallette.danger,
                }}
                onClick={handleItemReject}
                label="Reject"
              />
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default HeaderSection;
