import { FC } from "react";
import InputField from "common/input_field";
import SelectInputField from "common/select_input_field";
import { countryList } from "../register_form/data";
import { RegisterFormNames } from "../register_form/form_data";
import { Grid } from "@mui/material";

const AddAdminForm: FC = () => {
  return (
    <>
      <Grid item xs={12} md={5.5}>
        <InputField
          inputMode="text"
          label="Full name"
          placeholder="Username"
          name={RegisterFormNames.username}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <InputField
          inputMode="email"
          label="Email"
          name={RegisterFormNames.email}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <InputField
          inputMode="text"
          label="Phone Number"
          placeholder="E.g 581196897"
          name={RegisterFormNames.mobile}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <SelectInputField
          label="Country"
          inputMode="search"
          placeholder="Choose Country"
          name={RegisterFormNames.country}
          dataList={countryList}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <InputField
          inputMode="text"
          label="University / Organization"
          placeholder="Univerisity"
          name={RegisterFormNames.university}
        />
      </Grid>
      <Grid item xs={12} md={5.5}>
        <SelectInputField
          label="Nationality"
          inputMode="search"
          placeholder="Nationality"
          name={RegisterFormNames.nationality}
          dataList={countryList}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <InputField
          inputMode="text"
          label="Password"
          type="password"
          placeholder="Choose Password"
          name={RegisterFormNames.password}
        />
      </Grid>
    </>
  );
};

export default AddAdminForm;
