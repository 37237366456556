import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Event } from "types/table_event";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { videoActions } from "store/video_slice";
import fetchVideosList from "utils/get_videos";
import updateOnlineVideo from "utils/update_online_video";
import { speakerClassData } from "components/filters_modal/form";
import { useRefreshTokenMutation } from "store/api/auth_service/endpoints";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { UploadPrivacy } from "types/privacy";

const useOnlineVideos = () => {
  const [category, setCategroy] = useState<number>();
  const [status, setStatus] = useState<UploadedFileStatus>();
  const [privacy, setPrivacy] = useState<UploadPrivacy>();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const data = useAppSelector((state) => state.video);

  const dispatch = useAppDispatch();

  const [refresh] = useRefreshTokenMutation();

  const navigate = useNavigate();

  const fetchVideoListing = async () => {
    const { data, statusCode } = await fetchVideosList({
      token: access_token,
      category:
        category && category !== speakerClassData[0].value
          ? category.toString()
          : undefined,
      status: status && status !== UploadedFileStatus.ALL ? status : undefined,
      privacy:
        privacy && privacy !== UploadPrivacy.PUBLIC ? privacy : undefined,
    });
    if (statusCode === 401) {
      refresh({ token: access_token });
      await fetchVideoListing();
    }
    dispatch(videoActions.submitVideos(data));
  };

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);

    fetchVideoListing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, access_token, status]);

  const handleItemReject: Event = async (id: string | number) => {
    await updateOnlineVideo(
      { status: UploadedFileStatus.REJECTED },
      {
        id,
        token: access_token,
      }
    );
    fetchVideoListing();
  };

  const handleItemAccept: Event = async (id: string | number) => {
    await updateOnlineVideo(
      { status: UploadedFileStatus.ACCEPTED },
      {
        id,
        token: access_token,
      }
    );
    fetchVideoListing();
  };

  return {
    handleItemAccept,
    handleItemReject,
    data,
    privacy,
    setPrivacy,
    category,
    setCategroy,
    status,
    setStatus,
  };
};

export default useOnlineVideos;
