import { Methods } from "types/http";
import apiUrls from "../urls";
import { GenerateToken, PreResetPwd, ResetPwd, VerifyToken } from "../types";

export const generateTokenAction = (body: GenerateToken) => ({
  url: apiUrls.generateToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const refreshTokenAction = (body: VerifyToken) => ({
  url: apiUrls.refreshToken(),
  method: Methods.POST,
  body,
});

export const verifyTokenAction = (body: VerifyToken) => ({
  url: apiUrls.verifyToken(),
  method: Methods.POST,
  body,
});

export const resetPwdAction = (body: ResetPwd) => ({
  url: apiUrls.resetPwd(),
  method: Methods.POST,
  body,
});

export const preResetPwdAction = (body: PreResetPwd) => ({
  url: apiUrls.preResetPwd(),
  method: Methods.POST,
  body,
});

export const getRolesAction = () => ({
  url: apiUrls.getRoles(),
  method: Methods.GET,
});
