import { defineMessages } from "react-intl";

const scope = "LoginForm";

export default defineMessages({
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: "Phone Number",
  },
  mobilePlaceholder: {
    id: `${scope}.mobilePlaceholder`,
    defaultMessage: "E.g 0581196897",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: "Password",
  },
  submitLabel: {
    id: `${scope}.submitLabel`,
    defaultMessage: "Submit",
  },
});
