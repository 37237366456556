import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;
