import messages from "./messages";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";

type ListItem = {
  label: string;
  Icon: React.JSX.Element;
};

/*
export const levelOneMenu: Array<ListItem> = [
  {
    label: messages.dashboard.id,
    Icon: <DashboardIcon />,
  },
  {
    label: messages.insights.id,
    Icon: <InsightsIcon />,
  },
  {
    label: messages.question_answer.id,
    Icon: <QuestionMarkIcon />,
  },
]; */

export const levelTwoMenu: Array<ListItem> = [
  {
    label: messages.setting.id,
    Icon: <SettingsIcon />,
  },
  /* {
    label: messages.logout.id,
    Icon: <LogoutIcon />,
  }, */
];
