import { FC } from "react";
import { Box, Rating, Typography } from "@mui/material";
import styles from "./styles";
import cropText from "utils/crop_text";

type Props = {
  rating: number;
  imageSrc: string;
  views: string;
  title: string;
};

const MostViewedItem: FC<Props> = ({ imageSrc, rating, views, title }) => {
  return (
    <Box data-testid="most-viewed-item-test-id" sx={styles.container}>
      <img
        style={{
          borderRadius: 10,
          overflow: "hidden",
          objectFit: "cover",
        }}
        src={imageSrc}
        width={200}
        height={80}
      />
      <Box sx={styles.column}>
        <Typography sx={styles.headerLabel}>{cropText(title, 35)}</Typography>
        <Rating
          sx={styles.rating}
          value={rating}
          size="small"
          max={5}
          precision={0.5}
        />
        <Typography sx={styles.views}>{views}</Typography>
      </Box>
    </Box>
  );
};

export default MostViewedItem;
