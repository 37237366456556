import { FC } from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import StyledDrawer from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactSVG } from "react-svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SidebarAction } from "store/side_bar_slice";
import DrawerHeader from "components/drawer_header";
import { levelTwoMenu } from "./data";
import { FormattedMessage } from "react-intl";
import Service from "services/Service";
import SidebarMenuItem from "components/sidebar_menu_item";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import messages from "./messages";
import { globalSliceActions } from "store/global_slice";
import { NavigationRoutes } from "navigation/routes";
import police from "assets/svg/dubai_police.svg";
import redLogo from "assets/svg/goverment_red_logo.svg";

const menuItems = Service.getServices();

const Drawer: FC = () => {
  const { sidebar, global } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    dispatch(SidebarAction.toggleSidebar());
  };

  const handleMenuButtonClick = (entry: Service) => {
    if (entry.getServiceGroup() === null) navigate(entry.getServiceRoute());
  };

  const handleLogout = () => {
    dispatch(globalSliceActions.signout());
    navigate(NavigationRoutes.login);
  };

  return (
    <StyledDrawer
      variant="permanent"
      anchor={global.lang.direction === "rtl" ? "right" : "left"}
      open={sidebar.open}
    >
      <DrawerHeader
        sx={{
          height: 90,
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 0.4,
            alignItems: "center",
            paddingX: 1,
          }}
        >
          <ReactSVG src={police} />
          <ReactSVG src={redLogo} />
        </Box>
      </DrawerHeader>
      <Divider />
      <List component="div" dir={global.lang.direction}>
        {menuItems.map(({ service, display }) => (
          <>
            {display === "main_route" ? (
              <ListItem
                key={service.getServiceName()}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleMenuButtonClick(service)}
              >
                <SidebarMenuItem entry={service} />
              </ListItem>
            ) : null}
          </>
        ))}
      </List>
      <Divider />
      <List component="div" dir={global.lang.direction}>
        {levelTwoMenu.map((entry) => (
          <ListItem key={entry.label} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebar.open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: sidebar.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {entry.Icon}
              </ListItemIcon>
              <ListItemText sx={{ opacity: sidebar.open ? 1 : 0 }}>
                <FormattedMessage id={entry.label} />
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem
          onClick={handleLogout}
          key={"logout"}
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sidebar.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sidebar.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: sidebar.open ? 1 : 0 }}>
              <FormattedMessage id={messages.logout.id} />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </StyledDrawer>
  );
};

export default Drawer;
