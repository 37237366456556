import apiUrls from "store/api/urls";
import axios from "axios";
import { MembershipQuery } from "types/membershipQuery";
import statusMapper from "types/status_mapper";
import { Data } from "components/table/data";
import { VideoServiceResponse } from "types/video_service_response";

const fetchVideosList = async (
  param: MembershipQuery
): Promise<{ data: Array<Data>; statusCode: number }> => {
  const apiResult = await axios.get(apiUrls.getVideoList({ ...param }), {
    baseURL: "https://api.livetips.crowddp.com/",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      Authorization: `Bearer ${param.token}`,
    },
  });

  const res: Array<Data> = apiResult.data.results.map(
    (entry: VideoServiceResponse) => ({
      name: entry.user.name,
      org: entry.user.org,
      status: statusMapper[entry.status],
      title: entry.title,
      id: entry.id,
    })
  );

  return { data: res, statusCode: apiResult.status };
};

export default fetchVideosList;
