import * as yup from "yup";

export enum REJECTMODALFORMPROPS {
  reason = "reason",
  description = "description",
}

const validationSchema: yup.Schema = yup.object({
  [REJECTMODALFORMPROPS.reason]: yup.string(),
  [REJECTMODALFORMPROPS.description]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [REJECTMODALFORMPROPS.reason]: "",
  [REJECTMODALFORMPROPS.description]: "",
};

export { validationSchema, initialValues };
