import { Methods } from "types/http";
import apiUrls from "../urls";
import { VideoQuery } from "types/video_query";
import { UploadVideoRequest, VideoDetailsRequest } from "./endpoints";

export const uploadVideo = (body: FormData) => ({
  url: apiUrls.createVideo(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

export const checkHealthAction = () => ({
  url: apiUrls.healthCheck(),
  method: Methods.GET,
});

export const getVideoListAction = (param: VideoQuery) => ({
  url: apiUrls.getVideoList(param),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
  },
});

export const createVideoAction = ({ token, body }: UploadVideoRequest) => ({
  url: apiUrls.createVideo(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    "Content-Type": "multipart/form-data; boundry=6000000000",
    Authorization: `Bearer ${token}`,
  },
});

export const getVideoByIdAction = (param: VideoDetailsRequest) => ({
  url: apiUrls.videoById(param?.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    Authorization: `Bearer ${param.token}`,
  },
});

export const updateVideoByIdAction = (id: string) => ({
  url: apiUrls.videoById(id),
  method: Methods.PUT,
});

export const deleteVideoByIdAction = (id: string) => ({
  url: apiUrls.videoById(id),
  method: Methods.DELETE,
});

export const partialUpdateVideoByIdAction = (id: string) => ({
  url: apiUrls.videoById(id),
  method: Methods.PATCH,
});
