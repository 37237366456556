import { FC, useContext } from "react";
import { MenuItem, Select, Stack } from "@mui/material";
import styles from "./styles";
import {
  FILTER_MODAL_PROPS,
  privacyList,
  speakerClassData,
  statusList,
} from "./form";
import FilterContext from "context/filter_context";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/privacy";

const FiltersModal: FC = () => {
  const { status, setCategroy, setStatus, setPrivacy, privacy, category } =
    useContext(FilterContext);

  return (
    <Stack direction="row" width="40%" columnGap={3} sx={styles.container}>
      <Select
        name={FILTER_MODAL_PROPS.country}
        label="Category"
        placeholder="Filter by Category"
        onChange={(e) => setCategroy(e.target.value as number)}
        value={category ?? 13}
        sx={{ backgroundColor: "white" }}
        fullWidth
      >
        {speakerClassData.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.label}
          </MenuItem>
        ))}
      </Select>
      <Select
        name={FILTER_MODAL_PROPS.privacy}
        label="Status"
        placeholder="Filter by Status"
        onChange={(e) => setPrivacy(e.target.value as UploadPrivacy)}
        value={privacy ?? UploadPrivacy.PUBLIC}
        sx={{ backgroundColor: "white" }}
        fullWidth
      >
        {privacyList.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.label}
          </MenuItem>
        ))}
      </Select>
      <Select
        name={FILTER_MODAL_PROPS.status}
        label="Status"
        placeholder="Filter by Status"
        onChange={(e) => setStatus(e.target.value as UploadedFileStatus)}
        value={status ?? UploadedFileStatus.ALL}
        sx={{ backgroundColor: "white" }}
        fullWidth
      >
        {statusList.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default FiltersModal;
