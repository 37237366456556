import rootApi from "store/api";
import {
  createMembershipAction,
  deleteMembershipByIdAction,
  getMembershipByIdAction,
  getMembershipList,
  partialUpdateMembershipByIdAction,
  updateMembershipByIdAction,
} from "./action_creators";
import { GetById, MembershipRequest, UserResponse } from "../types";
import { MembershipQuery } from "types/membershipQuery";
import { MembershipServiceResponse } from "types/membership_service_response";
import { Speaker } from "types/speaker";
import statusMapper from "types/status_mapper";

export type UploadVideoRequest = {
  token: string;
  body: FormData;
};

export const membershipServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMembershipList: builder.query<Array<Speaker>, MembershipQuery>({
      query: (body) => getMembershipList(body),
      transformResponse: (response: {
        results: Array<MembershipServiceResponse>;
      }) => {
        if (!response) return [];
        const res = response.results.map((entry) => ({
          name: entry.user.name,
          email: entry.user.email,
          org: entry.user.org,
          mobile: entry.user.mobile,
          status: statusMapper[entry.status],
          id: entry.id,
        }));
        return res;
      },
    }),
    postMembership: builder.mutation<void, MembershipRequest>({
      query: (body) => createMembershipAction(body),
    }),
    getMembershipById: builder.query<any, GetById>({
      query: (param) => getMembershipByIdAction(param),
      transformResponse: (response: UserResponse) => {
        const res = {
          id: response.id,
          customerId: response.user.customerId,
          mobile: response.user.mobile,
          email: response.user.email,
          status: response.status,
          name: response.user.name,
          lng: response.user.lng,
          category: response.category.nameEn,
          org: response.user.org,
          nationality: response.user.nationality,
          country: response.user.country,
          role: response.user.role,
          gender: response.gender,
          about: response.user.about,
          cover_image: response.cover_image,
          profile_picture: response.profile_picture,
          city: response.city,
          identity: response.identity,
          bio: response.bio,
          article_url: response.article_url,
          article_file: response.article_file,
        };
        return res;
      },
    }),
    deleteMembershipById: builder.mutation<void, string>({
      query: (id) => deleteMembershipByIdAction(id),
    }),
    updateMembershipById: builder.mutation<void, string>({
      query: (id) => updateMembershipByIdAction(id),
    }),
    partialUpdateMembershipById: builder.mutation<void, string>({
      query: (id) => partialUpdateMembershipByIdAction(id),
    }),
  }),
});

export const {
  useDeleteMembershipByIdMutation,
  useGetMembershipByIdQuery,
  useGetMembershipListQuery,
  usePartialUpdateMembershipByIdMutation,
  usePostMembershipMutation,
  useUpdateMembershipByIdMutation,
} = membershipServiceApi;
