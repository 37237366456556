import { UploadPrivacy } from "types/privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";
import * as yup from "yup";

export enum FILTER_MODAL_PROPS {
  country = "country",
  status = "status",
  privacy = "privacy",
}

const validationSchema: yup.Schema = yup.object({
  [FILTER_MODAL_PROPS.country]: yup.string(),
  [FILTER_MODAL_PROPS.status]: yup.string(),
  [FILTER_MODAL_PROPS.privacy]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [FILTER_MODAL_PROPS.status]: "",
  [FILTER_MODAL_PROPS.country]: "",
  [FILTER_MODAL_PROPS.privacy]: "",
};

export const statusList = [
  { label: "All", value: UploadedFileStatus.ALL },
  { label: "Accepted", value: UploadedFileStatus.ACCEPTED },
  { label: "Drafted", value: UploadedFileStatus.DRAFTED },
  { label: "Deleted", value: UploadedFileStatus.DELETED },
  { label: "Pending", value: UploadedFileStatus.PENDING },
  { label: "Rejected", value: UploadedFileStatus.REJECTED },
];

export const privacyList = [
  { label: "Public", value: UploadPrivacy.PUBLIC },
  { label: "Dubai Police", value: UploadPrivacy.DUBAI_POLICE },
];

export type SpeakerClass = { value: number; label: string };

export const speakerClassData: Array<SpeakerClass> = [
  { label: "All Categroies", value: 13 },
  { label: "Technology", value: 1 },
  { label: "Environment", value: 2 },
  { label: "Science", value: 3 },
  { label: "Business", value: 4 },
  { label: "Social Change", value: 5 },
  { label: "Health", value: 6 },
  { label: "Nature", value: 7 },
  { label: "Community", value: 9 },
  { label: "Identity", value: 8 },
  { label: "Humanity", value: 10 },
  { label: "Communication", value: 11 },
  { label: "The Future", value: 12 },
];

export { validationSchema, initialValues };
