export enum NavigationRoutes {
  blog = "/blog",
  insights = "/insights",
  dashboard = "/",
  login = "/login",
  forgotPassword = "/login/reset-password",
  signup = "/register",
  podcast = "/podcast",
  admins = "/admins",
  online = "/online",
  livePodcast = "/podcast/live",
  speakers = "/speakers",
  admin_list = "/admin_list",
}
