import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GenerateTokenResponse, UserResponse } from "store/api/types";
import { Local } from "types/local";
import Role from "types/role";

enum LanguageDirection {
  rightToLeft = "rtl",
  leftToRight = "ltr",
}

type UserCredentials = {
  transactionId: string;
  token: GenerateTokenResponse;
};

type Language = {
  prefix: Local;
  direction: LanguageDirection;
};

type State = {
  lang: Language;
  darkTheme?: boolean;
  permission?: Role;
  credentials: UserCredentials;
  speakerProfile: UserResponse;
};

const initialState: State = {
  lang: {
    prefix: Local.English,
    direction: LanguageDirection.leftToRight,
  },
  speakerProfile: {
    id: 0,
    user: {
      customerId: 0,
      mobile: "",
      email: "",
      status: "",
      name: "",
      lng: "",
      org: "",
      nationality: "",
      country: "",
      role: "officer",
      gender: "male",
      about: null,
      createdAt: "",
      updatedAt: "",
    },
    category: {
      categoryId: 1,
      nameAr: "",
      nameEn: "",
      createdAt: "",
      updatedAt: "",
    },
    cover_image:
      "https://livetips.blob.core.windows.net/livetips/speaker/cover_image/f7c40763-c32b-47ed-a785-91a866b2ab48.jpg",
    profile_picture: "",
    cover_image_file: null,
    profile_picture_file: null,
    city: "",
    country: "",
    identity:
      "https://livetips.blob.core.windows.net/livetips/speaker/identity/7f4669ab-d638-4cf8-b585-c47d7ad4b30a.jpg",
    bio: "Bio...",
    gender: 0,
    article_url: "https://www.youtube.com/",
    article_file: null,
    status: 3,
    created_date: "",
    updated_date: "",
  },
  darkTheme: false,
  credentials: {
    transactionId: "",
    token: {
      access_token: "",
      expires_in: 0,
      refresh_expires_in: 0,
      refresh_token: "",
      token_type: "Bearer",
      scope: "email openid profile",
      userData: {
        email: "",
        org: "",
        customerId: "",
        email_verified: true,
        given_name: "",
        name: "",
        preferred_username: "",
      },
    },
  },
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeLanguage: (state: State, { payload }: PayloadAction<Local>) => {
      state.lang.prefix = payload;
      payload === Local.Arabic
        ? (state.lang.direction = LanguageDirection.rightToLeft)
        : (state.lang.direction = LanguageDirection.leftToRight);
      return state;
    },
    changeTheme: (state: State) => {
      !state.darkTheme;
      return state;
    },
    submitUserTransactionId: (
      state: State,
      { payload }: PayloadAction<string>
    ) => {
      state.credentials.transactionId = payload;
      return state;
    },
    submitUserCredentials: (
      state: State,
      { payload }: PayloadAction<GenerateTokenResponse>
    ) => {
      state.credentials.token = payload;
      return state;
    },
    submitUserData: (
      state: State,
      { payload }: PayloadAction<UserResponse>
    ) => {
      state.speakerProfile = payload;
      return state;
    },
    signout: (state: State) => {
      state.credentials = {
        transactionId: "",
        token: {
          access_token: "",
          expires_in: 0,
          refresh_expires_in: 0,
          token_type: "Bearer",
          scope: "email openid profile",
          refresh_token: "",
          userData: {
            email_verified: true,
            customerId: "",
            name: "",
            preferred_username: "",
            org: "",
            given_name: "",
            email: "",
          },
        },
      };
      return state;
    },
  },
});

export default globalSlice.reducer;
export const globalSliceActions = globalSlice.actions;
