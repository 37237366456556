import { FC } from "react";
import { Box, Button } from "@mui/material";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import messages from "./messages";
import { useIntl } from "react-intl";
import uae from "assets/svg/uae_pass.svg";

const UaePassButton: FC = () => {
  const intl = useIntl();
  return (
    <Box sx={styles.container}>
      <Button sx={styles.button} variant="contained">
        {intl.formatMessage(messages.uaePass)}
        <ReactSVG
          style={{ paddingInlineStart: 15, paddingBlockStart: 5 }}
          width={20}
          height={20}
          src={uae}
        />
      </Button>
    </Box>
  );
};

export default UaePassButton;
