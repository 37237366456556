import { SxProps } from "@mui/material";
import pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  button: {
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
    },
    paddingY: 1.4,
    paddingX: 6,
    marginTop: 3,
    borderRadius: 3,
    backgroundColor: pallette.primary,
  },
};

export default styles;
