import { Methods } from "types/http";
import apiUrls from "../urls";
import { GetById, MembershipRequest } from "../types";
import { MembershipQuery } from "types/membershipQuery";

export const getMembershipList = (param: MembershipQuery) => ({
  url: apiUrls.getMembershipList(param),
  method: Methods.GET,
  Lng: "EN",
  Accept: "application/json",
  Authorization: `Bearer ${param.token}`,
});

export const createMembershipAction = ({ body, token }: MembershipRequest) => ({
  url: apiUrls.postMembership(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getMembershipByIdAction = ({ id, token }: GetById) => ({
  url: apiUrls.membershipById(id as string),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const updateMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.PATCH,
});

export const deleteMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.DELETE,
});

export const partialUpdateMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.PATCH,
});
