import { FC } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ServiceGroup } from "services/types/service_group";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

type Props = {
  serviceGroup: ServiceGroup;
  open: boolean;
};

const Collapsable: FC<Props> = ({ serviceGroup, open }) => {
  const navigate = useNavigate();

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {serviceGroup !== null &&
          serviceGroup.map((subEntry) => (
            <ListItemButton
              onClick={() => navigate(subEntry.getServiceRoute())}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>{subEntry.getComponent()}</ListItemIcon>
              <ListItemText>
                <FormattedMessage id={subEntry.getServiceName()} />
              </ListItemText>
            </ListItemButton>
          ))}
      </List>
    </Collapse>
  );
};

export default Collapsable;
