export const propMapper = {
  media: "Media",
  customerId: "User identification",
  email: "Email Address",
  status: "User Status",
  lng: "Language",
  org: "Origanization",
  country: "Country",
  gender: "Gender",
  profile_picture: "Profile Picture",
  identity: "Emirates Id File",
  bio: "Biography",
  city: "City",
  about: "About",
  role: "User Role",
  nationality: "Nationality",
  category: "Category",
  name: "Name",
  mobile: "Mobile Number",
  id: "identification",
};
