import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootApi from "./api/index";
import loginReducer from "./login_slice/index";
import globalReducer from "./global_slice/index";
import sidebarReducer from "./side_bar_slice/index";
import speakerReducer from "./speaker_slice";
import videoReducer from "./video_slice";
import podcastReducer from "./podcast_slice";
import snackReducer from "./snack_slice";
import tableReducer from "./table_slice";
import { authApi } from "./api/auth_service/endpoints";
import { membershipServiceApi } from "./api/membership_service/endpoints";
import { videoServiceApi } from "./api/video_service/endpoints";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { identityServiceApi } from "./api/identity_service/endpoints";
import modalReducer from "./modals_slice";
import refreshTokenMiddleware from "./middlewares/refresh_token_middleware";

const persistConfig = {
  key: "root",
  storage,
  timeout: 200,
};

const reducers = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  login: loginReducer,
  global: globalReducer,
  sidebar: sidebarReducer,
  speaker: speakerReducer,
  table: tableReducer,
  video: videoReducer,
  podcast: podcastReducer,
  snack: snackReducer,
  modals: modalReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(membershipServiceApi.middleware)
      .concat(identityServiceApi.middleware)
      .concat(videoServiceApi.middleware)
      .concat(refreshTokenMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

export default () => {
  const persistor = persistStore(store);
  return { store, persistor };
};
