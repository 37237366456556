import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 0,
    borderRadius: 3,
    width: "100%",
  },
};

export default styles;
