import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import styles from "./styles";
import RegisterForm from "./register_form";
import SnackBar from "components/snack_bar";

const Admins: FC = () => {
  return (
    <>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.font}>Add Admin</Typography>
      </Box>
      <Box sx={styles.container}>
        <Card extraStyle={styles.cardStyle}>
          <RegisterForm />
        </Card>
        <SnackBar />
      </Box>
    </>
  );
};

export default Admins;
