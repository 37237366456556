import {
  Checkbox,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { NavigationRoutes } from "navigation/routes";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import color_pallette from "theme/color_pallette";
import { Event } from "types/table_event";
import { WithId } from "types/table_generic";
import { Order, getComparator } from "utils/comparator";
import { stableSort } from "utils/stable_sort";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch } from "store/hooks";
import { modalSliceActions } from "store/modals_slice";

type Props<T extends WithId> = {
  rootPath?: NavigationRoutes;
  order: Order;
  orderBy: keyof T;
  page: number;
  rowsPerPage: number;
  selected: readonly number[];
  onSelect: (event: any, id: number) => void;
  rows: T[];
  headers: Array<keyof T>;
  handleAccept: Event;
  handleReject: Event;
};

const styles: Record<string, SxProps> = {
  background: {
    backgroundColor: color_pallette.backgroundGray,
    cursor: "pointer",
  },
  cursor: { cursor: "pointer" },
};

function EnhancedTableBody<T extends WithId>({
  order,
  orderBy,
  page,
  handleAccept,
  rowsPerPage,
  selected,
  rows,
  onSelect,
  headers,
  rootPath,
}: Props<T>) {
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const toDetail = (id: string | number) => {
    navigate(`${rootPath ? rootPath : location.pathname}/${id}`);
  };

  const handleRejectModal = (id: string | number) => {
    dispatch(modalSliceActions.toggleRejectModal({ show: true, id }));
  };

  const paginatedRows = useMemo(
    () =>
      stableSort<T>(rows, getComparator<T>(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <TableBody>
      {paginatedRows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            role="checkbox"
            selected={isItemSelected}
            sx={index % 2 === 0 ? styles.background : styles.cursor}
          >
            <Checkbox
              color="primary"
              role="checkbox"
              onClick={(event) => onSelect(event, row.id)}
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
            {headers
              .filter((entry) => entry !== "status")
              .map((entry) => (
                <TableCell onClick={() => toDetail(row.id)}>
                  {(row as any)[entry]}
                </TableCell>
              ))}
            <TableCell>
              <Typography
                sx={{
                  color:
                    row["status"] === "Accepted"
                      ? color_pallette.iconTint
                      : row["status"] === "Rejected"
                      ? color_pallette.danger
                      : "black",
                }}
              >
                {row["status"]}
              </Typography>
            </TableCell>
            <TableCell>
              <Stack direction="row" columnGap={5}>
                {row.status === "Pending" && (
                  <CheckIcon
                    sx={{ color: color_pallette.iconTint }}
                    onClick={() => handleAccept(row.id)}
                  />
                )}
                {row.status === "Pending" && (
                  <CancelIcon
                    sx={{ color: color_pallette.danger }}
                    onClick={() => handleRejectModal(row.id)}
                  />
                )}
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

export default EnhancedTableBody;
