import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import MostViewedItem from "components/most_viewed_item";
import { DisplayedSpeaker } from "types/displayed_speaker";

type Props = {
  data: Array<DisplayedSpeaker>;
  onItemClick: (id: string) => void;
};

const MostViewedList: FC<Props> = ({ data, onItemClick }) => {
  return (
    <Box sx={styles.container}>
      {data.map((entry) => (
        <Box onClick={() => onItemClick(entry.id)} sx={styles.listItem}>
          <MostViewedItem
            imageSrc={entry.profile_picture}
            views="0 views"
            title={entry.name}
            rating={0}
            key={entry.id}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MostViewedList;
