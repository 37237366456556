import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FormNames } from "pages/login_page/login_form/form_data";
import clearStorage from "utils/clear_storage";

export type State = {
  [FormNames.mobile]: string;
  [FormNames.password]: string;
};

const initialState: State = {
  [FormNames.mobile]: "",
  [FormNames.password]: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    submitLoginForm: (state: State, { payload }: PayloadAction<State>) => {
      state = payload;
      return state;
    },
    signout: (state: State) => {
      state = {} as State;
      clearStorage();
      return state;
    },
  },
});

export default loginSlice.reducer;
export const loginActions = loginSlice.actions;
